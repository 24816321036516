import { FC, useCallback, useEffect, useMemo, useState } from "react";
import type { TableHeading } from "types/Table";
import { ChildrenTable } from "types/Table";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { CLIENT_QUOTE_HISTORY, CLIENT_REFERRAL_LOG } from "../../Services/Queries/Queries";
import { Params } from "../../../../types/Main";
import { setNotification, userContextData } from "../../../../helpers/cache";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import { DataCollectionItem } from "../../../../types/AdminDashboard";
import { Link } from "react-router-dom";
import { convertDateToUSFormat } from "../../../../helpers/utils";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";
import { IReferralLog } from "components/model/IOrderProduct";
import { IPagableCollection } from "components/model/IPagableCollection";
import { CONFIG } from "config";

const EditClientReferralLog: FC<{ clientId?: number }> = ({ clientId }) => {

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [countPages, setCountPages] = useState(0);

  const { data: referralLog, loading: isReferralLogLoading, refetch } = useQuery<IPagableCollection<'clientReferralLog', IReferralLog>>(CLIENT_REFERRAL_LOG, {
    variables: {
      clientId,
      limit: pageSize,
      page: currentPage,
    },
  });

  // -- Change pages count
  useEffect(() => {
    setCountPages(() => (referralLog?.clientReferralLog?.dataCollection?.length || 0) / pageSize);
  }, [referralLog?.clientReferralLog?.dataCollection?.length]);

  const tableHead = useMemo((): TableHeading => [
      { name: "createdAt", label: "created" },
      { name: "amount", label: "Amount" },
      { name: "type", label: "Type" },
      { name: "status", label: "Status" },
      { name: "quote", label: "Quote" },
      { name: "user", label: "User" },
      { name: "description", label: "Description" },
    ], []);

  const getStatus = (status?: number): string => (CONFIG.referralLog.status as any)?.[status || 0] || CONFIG.referralLog.status[0];

  return (
    <section>
      <TableWithHeaderNew
        header="Referral Transaction Log"
        heading={tableHead}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        loading={isReferralLogLoading}
        children={(referralLog?.clientReferralLog?.dataCollection || []).map(({ id, createdAt, amount, type, status, quote_id, user, description }) => ({
            id,
            row: {
              createdAt: createdAt
                ? convertDateToUSFormat(createdAt)
                : '',
              amount: amount && !Number.isNaN(+amount) && +amount > 0
                ? `+ ${amount}`
                : amount,
              type,
              status: getStatus(status),
              quote: quote_id 
                ? <Link to={`/quotes/${quote_id}`} target="_blank">#{quote_id}</Link> 
                : '-',
              user: user?.email || '-' ,
              description,
            },
            additionalClassRow: `
              ${
                (!amount || Number.isNaN(+amount) && '')
                || (+amount > 0 && 'text-green-600')
                || (+amount > 0 && 'text-red-500')
              }
            `
        }))}
        setIsNeedUpdateTableData={() => {
          // setIsNeedUpdateData(true);
          refetch();
        }}
      />
    </section>
  );
};

export default EditClientReferralLog;
