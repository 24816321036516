import { gql } from "@apollo/client";

export const QUOTE_ITEM_QUERY = gql`
  query quote($id: Int!) {
    quote(id: $id) {
      id
      client {
        id
        user {
          id
          userName
          firstName
          lastName
        }
        company
        accessLevelCd
        accountName
        industryTag {
          id
          name
        }
        webAdress
      }
      user {
        id
      }
      quoteAdditionalRows {
        id
        description
        quickQuoteAdditionalInfo {
          id
        }
      }
      orderProducts {
        id
        product {
          id
        }
        quantity
        unitPrice
        description
        productOptionsString
        isForSupplier
        recordType
        priceTable {
          id
          name
        }
        sort
      }
      shippingAddress {
        id
        address
      }
      billingAddress {
        id
        address
      }
      leadSource
      quoteType
      supplier {
        id
      }
      quoteOrder {
        id
        note
        specialInstructions
        misc
        paymentType
        paymentStatus
        shippingMethod
        trackingNumber1
        trackingNumber2
        clientCreditCard {
          id
          holderName
          cardNumber
        }
        useUpsFedexAcct
        customerPaysShipping
        watchTracking1
        watchTracking2
        dateWasPlaced
      }
      useInventory
      requiredDeliveryDate
      shippingTax
      quoteIdPublic
      withoutTotal
      withoutTax
      status
      clientContact {
        id
        firstName
        sex
        lastName
        email
      }
      quoteAdditionalRows {
        description
      }
      note
      useAdditionalLine
      leadSourceStatus
      quotePaymentLinkTpls {
        id
        quoteId
        createdAt
        linkCheckoutSessionId
        linkExpirationAt
        linkHref
        updatedAt
        amount
        payload
        status
        linkGeneratorHref
        transactionId
        transactionCardType
        transactionCardNumberFirst
        transactionCardNumberLast
        transactionCardExpiry
        transactionType
        transactionAuth
        transactionRefNum
        transactionAt
        emailTplId
        receivedEmailTplId
        dontChangeOrderStatus
      }
    }
  }
`;
