import { ActionButton } from 'components/Buttons';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

const EditQuoteReferralForm: FC<{
  balance: number,
  balanceBlocked?: number,
  applied?: number,
  loading?: boolean,
  applyBonuses?: (amount: number) => unknown, 
  deleteBonuses?: () => unknown, 
  clientId?: number,
}> = ({
  balance,
  balanceBlocked  = 20,
  applied,
  loading = false,
  applyBonuses,
  deleteBonuses,
  clientId,
}) => {
  const [localApplied, setLocalApplied] = useState<string>(applied ? String(applied) : ''); 

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value.trim();
    if (amount && Number.isNaN(`${amount}0`)) return;

    setLocalApplied(() => amount && Number(amount) > balance
      ? String(balance.toFixed(2))
      : amount
    );            
  }

  return (
    <div className="flex justify-end gap-[.7em] items-end">
      <div className="flex gap-2 items-center">
        <label className="text-[14px] leading-5 text-[#393939]">
          {clientId
            ? <Link to={`/clients/${clientId}/referral`} target='_blank'>Referral Bonuses:</Link>
            : `Referral Bonuses:`
          }
        </label>
        <div className="">
          <div className="gap-[5px] text-[1.1em] border justify-center border-[#D5D5D5] h-[28px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer px-[6px] flex items-center placeholder:font-heltivica">
            <div className="text-black">
              {balance.toFixed(2)}
            </div>
            {!!balanceBlocked && (
              <div className="text-[#838281] text-[0.9em]">
                + {balanceBlocked}
              </div>
            )}
          </div>          
        </div>
      </div>
      <div className="flex items-end pb-[1px] text-xl">
        &rarr;
      </div>
      <div className="flex gap-1">
        <div>
          <input 
            value={localApplied || ''}
            placeholder='Amount'
            onChange={handleChange}
            className="w-[70px] text-center border border-[#D5D5D5] h-[28px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer px-[3px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica"
          />      
        </div>
      </div>
      <div className="flex gap-2 btn-group">
        <ActionButton
          buttonName="Apply"
          icon="icon-floppy"
          className={`
            ${!localApplied ? 'opacity-50' : ''}
            btn-blue btn btn-small
          `}
          buttonType="button"
          loading={loading}
          onClick={() => applyBonuses && applyBonuses(Number(localApplied))}
          disabled={!localApplied}
        />
        {!!applied && (
          <ActionButton
            buttonName="Cancel"
            icon="icon-trash-empty"
            className="btn-warning btn btn-small"
            loading={loading}
            onClick={() => deleteBonuses && deleteBonuses()}
          />
        )}
      </div>
    </div>
  );
}

export default EditQuoteReferralForm;
