import { useLazyQuery } from '@apollo/client';
import Card from 'components/Card/Card';
import { CLIENT_ITEM_TINY_QUERY } from 'modules/Clients/Services/Queries/ClientItemQuery';
import EditQuoteQuoteInfoSearchBox from 'modules/Quotes/Pages/EditQuotes/EditQuoteQuoteInfoSearchBox';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const EditClientClientInfoRefer: FC<{
  clientId?: number,
  onChange?: (id: number | undefined) => unknown,
}> = ({
  clientId,
  onChange,
}) => { 
  const [clientIdState, setClientIdState] = useState<number | undefined>(clientId);
  const [clientSeted, setClientSeted] = useState<boolean>(false);
  useEffect(() => {     
    setClientIdState(() => clientId);
    setClientSeted(() => true);
  }, [clientId]);

  const [
    fetchClient,
    {
      data: client,
      loading,
      refetch,
    },
  ] = useLazyQuery(CLIENT_ITEM_TINY_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  // -- Load client details
  useEffect(() => {
    clientIdState && (async () => {
      await fetchClient({ variables: { id: clientIdState } });
    })();
  }, [clientIdState]);

  // -- Change global info
  useEffect(() => {
    clientSeted
    && clientIdState !== clientId 
    && onChange 
    && onChange(clientIdState);
  }, [clientIdState]); 

  return (
    <Card
      title="Client Refer"
      className="box-4"
      actions={
        <button
          className="btn btn-small btn-warning"
          onClick={() => setClientIdState(() => undefined)}
          disabled={!clientIdState}
        >
          <i className="icon-trash-empty icon-medium" />
        </button>
      }
    >
      <div className="flex flex-col text-sm gap-2">
        {loading
          ? <div className=""><i className="inline-block animate-spin icon-spin4" /></div>
          : (!clientIdState || !client?.client)
            ? <div className="">No Refer</div> 
            : (
              <Link className="truncate" to={`/clients/${client?.client?.id}`} target='_blank'>
                {client?.client?.defaultContact?.firstName} {client?.client?.defaultContact?.lastName} {client?.client?.defaultContact?.email}
              </Link>
            )
        }
        <EditQuoteQuoteInfoSearchBox 
          value={1}
          onChange={(id) => setClientIdState(() => id)}
        />
      </div>
    </Card>
  );
}

export default EditClientClientInfoRefer;
