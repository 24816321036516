import { gql } from "@apollo/client";

export const CREATE_QUOTE = gql`
  mutation createQuote(
    $client: quoteClient!
    $userId: Int
    $note: String
    $leadSource: Int
    $leadSourceStatus: Int!
    $status: Int!
    $additionalQuoteLine: [createQuoteAdditionalQuoteLine]
    $quoteType: Int
    $supplierId: Int
    $useInventory: Boolean
    $requiredDeliveryDate: String
    $shippingTax: Float
    $quoteIdPublic: Int
    $withoutTotal: Boolean
    $withoutTax: Boolean
    $quoteFollowUp: Boolean
    $quoteFollowUpStatus: Int
    $autoFollowUpCount: Int
    $timeZoneId: Int
    $nextFollowUpTime: String
    $callReviewStatus: Boolean
    $reportStartTime: String
    $isWorkingTimeAdd: Boolean
    $uaClientId: String
    $reviewInvitationUrl: String
    $orderProducts: [createQuoteOrder]
    $useAdditionalLine: Boolean
  ) {
    createQuote(
      client: $client
      userId: $userId
      note: $note
      leadSource: $leadSource
      leadSourceStatus: $leadSourceStatus
      status: $status
      additionalQuoteLine: $additionalQuoteLine
      quoteType: $quoteType
      supplierId: $supplierId
      useInventory: $useInventory
      requiredDeliveryDate: $requiredDeliveryDate
      shippingTax: $shippingTax
      quoteIdPublic: $quoteIdPublic
      withoutTotal: $withoutTotal
      withoutTax: $withoutTax
      quoteFollowUp: $quoteFollowUp
      quoteFollowUpStatus: $quoteFollowUpStatus
      autoFollowUpCount: $autoFollowUpCount
      timeZoneId: $timeZoneId
      nextFollowUpTime: $nextFollowUpTime
      callReviewStatus: $callReviewStatus
      reportStartTime: $reportStartTime
      isWorkingTimeAdd: $isWorkingTimeAdd
      uaClientId: $uaClientId
      reviewInvitationUrl: $reviewInvitationUrl
      orderProducts: $orderProducts
      useAdditionalLine: $useAdditionalLine
    ) {
      id
    }
  }
`;

export const UPDATE_QUOTE = gql`
  mutation updateQuote(
    $id: Int!
    $client: updateQuoteClient
    $userId: Int
    $note: String
    $leadSource: Int
    $leadSourceStatus: Int
    $status: Int
    $shippedEmailTplId: Int
    $additionalQuoteLine: [updateQuoteAdditionalQuoteLine]
    $quoteType: Int
    $supplierId: Int
    $useInventory: Boolean
    $requiredDeliveryDate: String
    $shippingTax: Float
    $quoteIdPublic: Int
    $withoutTotal: Boolean
    $withoutTax: Boolean
    $quoteFollowUp: Boolean
    $quoteFollowUpStatus: Int
    $autoFollowUpCount: Int
    $timeZoneId: Int
    $nextFollowUpTime: String
    $callReviewStatus: Boolean
    $reportStartTime: String
    $isWorkingTimeAdd: Boolean
    $uaClientId: String
    $reviewInvitationUrl: String
    $orderProducts: [updateQuoteOrder]
    $useAdditionalLine: Boolean
  ) {
    updateQuote(
      id: $id
      client: $client
      userId: $userId
      note: $note
      leadSource: $leadSource
      leadSourceStatus: $leadSourceStatus
      status: $status
      shippedEmailTplId: $shippedEmailTplId
      additionalQuoteLine: $additionalQuoteLine
      quoteType: $quoteType
      supplierId: $supplierId
      useInventory: $useInventory
      requiredDeliveryDate: $requiredDeliveryDate
      shippingTax: $shippingTax
      quoteIdPublic: $quoteIdPublic
      withoutTotal: $withoutTotal
      withoutTax: $withoutTax
      quoteFollowUp: $quoteFollowUp
      quoteFollowUpStatus: $quoteFollowUpStatus
      autoFollowUpCount: $autoFollowUpCount
      timeZoneId: $timeZoneId
      nextFollowUpTime: $nextFollowUpTime
      callReviewStatus: $callReviewStatus
      reportStartTime: $reportStartTime
      isWorkingTimeAdd: $isWorkingTimeAdd
      uaClientId: $uaClientId
      reviewInvitationUrl: $reviewInvitationUrl
      orderProducts: $orderProducts
      useAdditionalLine: $useAdditionalLine
    ) {
      id
    }
  }
`;

export const CREATE_QUOTE_EXPENSE = gql`
  mutation createQuoteExpense($quoteId: Int!, $expenseId: Int, $value: Float) {
    createQuoteExpense(
      quoteId: $quoteId
      expenseId: $expenseId
      value: $value
    ) {
      id
      value
      quote {
        id
      }
      expense {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_QUOTE_EXPENSE = gql`
  mutation updateQuoteExpense($id: Int!, $expenseId: Int, $value: Float) {
    updateQuoteExpense(id: $id, expenseId: $expenseId, value: $value) {
      id
      value
      quote {
        id
      }
      expense {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CASHBACK = gql`
  mutation updateCashback($id: Int!, $cashbackAmount: Float) {
    updateCashback(id: $id, cashbackAmount: $cashbackAmount) {
      cashbackAmount
    }
  }
`;

export const DELETE_QUOTE_EXPENSE = gql`
  mutation deleteQuoteExpense($ids: [Int]!) {
    deleteQuoteExpense(ids: $ids)
  }
`;

export const CREATE_QUOTE_COMMENT = gql`
  mutation createQuoteComment($quoteId: Int!, $userId: Int, $text: String) {
    createQuoteComment(quoteId: $quoteId, userId: $userId, text: $text) {
      id
      text
      quote {
        id
      }
      user {
        id
        userName
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_QUOTE_FILES = gql`
  mutation createQuoteFile(
    $quoteId: Int!
    $userId: Int
    $comment: String
    $files: [createQuoteFiles]!
  ) {
    createQuoteFile(
      quoteId: $quoteId
      comment: $comment
      userId: $userId
      files: $files
    ) {
      id
      title
      comment
      quote {
        id
      }
      user {
        id
        userName
      }
      createdAt
      updatedAt
      fileUid
      fileName
      file
    }
  }
`;

export const UPDATE_QUOTE_FILES = gql`
  mutation updateQuoteFile($id: Int!, $comment: String) {
    updateQuoteFile(id: $id, comment: $comment) {
      id
      comment
    }
  }
`;

export const DELETE_QUOTE_FILES = gql`
  mutation deleteQuoteFile($ids: [Int]!) {
    deleteQuoteFile(ids: $ids)
  }
`;

export const CREATE_QUOTE_MESSAGE = gql`
  mutation createQuoteMessage(
    $quoteId: Int!
    $userId: Int
    $text: String
    $readable: Int
  ) {
    createQuoteMessage(
      quoteId: $quoteId
      userId: $userId
      text: $text
      readable: $readable
    ) {
      id
      text
      readable
      quote {
        id
      }
      user {
        id
        userName
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_SHIPPING_TRACK = gql`
  mutation createShippingTrack(
    $number: String!
    $quoteId: Int!
    $description: String,
    $watch: Boolean
  ) {
    createShippingTrack(
      number: $number
      quoteId: $quoteId
      description: $description,
      watch: $watch
    ) {
      id      
    }
  }
`;

export const DELETE_SHIPPING_TRACK = gql`
  mutation deleteShippingTrack($id: Int!) {
    deleteShippingTrack(id: $id)
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder(
    $id: Int!
    $shippingAddressId: Int
    $note: String
    $specialInstructions: String
    $misc: String
    $paymentType: Int
    $paymentStatus: Int
    $storeNameId: Int
    $autoFollowUp: Boolean
    $shippingMethod: Int
    $trackingNumber1: String
    $trackingNumber2: String
    $clientCreditCardId: Int
    $supplier: String
    $invoiceId: Int
    $useUpsFedexAcct: String
    $customerPaysShipping: Boolean
    $dropShip: Boolean
    $dateWasPlaced: String
    $watchTracking1: Boolean
    $watchTracking2: Boolean
    $isTracking1Delivery: Int
    $isTracking2Delivery: Int
  ) {
    updateOrder(
      id: $id
      shippingAddressId: $shippingAddressId
      note: $note
      specialInstructions: $specialInstructions
      misc: $misc
      paymentType: $paymentType
      paymentStatus: $paymentStatus
      storeNameId: $storeNameId
      autoFollowUp: $autoFollowUp
      shippingMethod: $shippingMethod
      trackingNumber1: $trackingNumber1
      trackingNumber2: $trackingNumber2
      clientCreditCardId: $clientCreditCardId
      supplier: $supplier
      invoiceId: $invoiceId
      useUpsFedexAcct: $useUpsFedexAcct
      customerPaysShipping: $customerPaysShipping
      dropShip: $dropShip
      dateWasPlaced: $dateWasPlaced
      watchTracking1: $watchTracking1
      watchTracking2: $watchTracking2
      isTracking1Delivery: $isTracking1Delivery
      isTracking2Delivery: $isTracking2Delivery
    ) {
      id
      quote {
        id
      }
      shippingAddress {
        id
        address
      }
      note
      specialInstructions
      misc
      paymentType
      paymentStatus
      storeNameId
      autoFollowUp
      shippingMethod
      trackingNumber1
      trackingNumber2
      clientCreditCard {
        id
      }
      supplier
      invoiceId
      useUpsFedexAcct
      customerPaysShipping
      dropShip
      dateWasPlaced
      watchTracking1
      watchTracking2
      isTracking1Delivery
      isTracking2Delivery
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder(
    $quoteId: Int!
    $shippingAddressId: Int
    $note: String
    $specialInstructions: String
    $misc: String
    $paymentType: Int
    $paymentStatus: Int
    $storeNameId: Int
    $autoFollowUp: Boolean
    $shippingMethod: Int
    $trackingNumber1: String
    $trackingNumber2: String
    $clientCreditCardId: Int
    $supplier: String
    $invoiceId: Int
    $useUpsFedexAcct: String
    $customerPaysShipping: Boolean
    $dropShip: Boolean
    $dateWasPlaced: String
    $watchTracking1: Boolean
    $watchTracking2: Boolean
    $isTracking1Delivery: Int
    $isTracking2Delivery: Int
  ) {
    createOrder(
      quoteId: $quoteId
      shippingAddressId: $shippingAddressId
      note: $note
      specialInstructions: $specialInstructions
      misc: $misc
      paymentType: $paymentType
      paymentStatus: $paymentStatus
      storeNameId: $storeNameId
      autoFollowUp: $autoFollowUp
      shippingMethod: $shippingMethod
      trackingNumber1: $trackingNumber1
      trackingNumber2: $trackingNumber2
      clientCreditCardId: $clientCreditCardId
      supplier: $supplier
      invoiceId: $invoiceId
      useUpsFedexAcct: $useUpsFedexAcct
      customerPaysShipping: $customerPaysShipping
      dropShip: $dropShip
      dateWasPlaced: $dateWasPlaced
      watchTracking1: $watchTracking1
      watchTracking2: $watchTracking2
      isTracking1Delivery: $isTracking1Delivery
      isTracking2Delivery: $isTracking2Delivery
    ) {
      id
      quote {
        id
      }
      shippingAddress {
        id
        address
      }
      note
      specialInstructions
      misc
      paymentType
      paymentStatus
      storeNameId
      autoFollowUp
      shippingMethod
      trackingNumber1
      trackingNumber2
      clientCreditCard {
        id
      }
      supplier
      invoiceId
      useUpsFedexAcct
      customerPaysShipping
      dropShip
      dateWasPlaced
      watchTracking1
      watchTracking2
      isTracking1Delivery
      isTracking2Delivery
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_QUOTE_IMAGE = gql`
  mutation createQuoteImage(
    $quoteId: Int!
    $comment: String
    $imageType: Int!
    $imageFiles: [createQuoteImages]!
  ) {
    createQuoteImage(
      quoteId: $quoteId
      comment: $comment
      imageType: $imageType
      imageFiles: $imageFiles
    ) {
      id
      title
      comment
      approved
      imageType
      createdAt
      updatedAt
      imageName
      imageFile
    }
  }
`;

export const UPDATE_QUOTE_IMAGE = gql`
  mutation updateQuoteImage($id: Int!, $comment: String, $approved: Boolean) {
    updateQuoteImage(id: $id, comment: $comment, approved: $approved) {
      id
      quote {
        id
      }
      comment
      approved
      imageType
      createdAt
      updatedAt
      imageName
      imageFile
    }
  }
`;

export const UPDATE_SHIPPING_TRACKS = gql`
  mutation updateShippingTracks($shippingTracksEditRows: [shippingTracksEditRows]!) {
    updateShippingTracks(shippingTracksEditRows: $shippingTracksEditRows) {
      id
      number
      description
      createdAt
      updatedAt
      watch
      quote {
        id
      }
      user {
        id
        email
        userName
        firstName
        lastName
      }
    }
  }
`;

export const DELETE_QUOTE_IMAGE = gql`
  mutation deleteQuoteImage($ids: [Int]!) {
    deleteQuoteImage(ids: $ids)
  }
`;

export const DEFAULT_FILTER = gql`
  query userDefaultFilter($userId: Int!, $page: String!) {
    userDefaultFilter(userId: $userId, page: $page) {
      id
      data
      page
      name
    }
  }
`;

export const DELETE_QUOTE = gql`
  mutation deleteQuote($ids: [Int]!) {
    deleteQuote(ids: $ids)
  }
`;
export const CREATE_TRANSACTON = gql`
  mutation createTransaction(
    $id: Int
    $type: createTransactionEnum!
    $quoteId: Int!
    $amount: Float
    $addOrderCredits: Boolean
  ) {
    createTransaction(
      id: $id
      type: $type
      quoteId: $quoteId
      amount: $amount
      addOrderCredits: $addOrderCredits
    )
  }
`;
export const DOWNLOAD_PDF = gql`
  query downloadPdf($quoteId: Int!, $type: String!, $template: String!) {
    downloadPdf(quoteId: $quoteId, type: $type, template: $template)
  }
`;

export const TRANSACTIONlOGS_COLLECTION_QUORY = gql`
  query transactionLogsByQuote($quoteId: Int!, $limit: Int!, $page: Int!) {
    transactionLogsByQuote(quoteId: $quoteId, limit: $limit, page: $page) {
      dataCollection {
        id
        cardHolder
        cardType
        amount
        cardNumber
        status
        authorizationNum
        refNum
        customRefNum
        transactionType
        expiry
        message
        transactionTag
        bankAnswer
        createdAt
        updatedAt
        quote {
          id
        }
        user {
          id
        }
      }
      totalNumberOfItems
    }
  }
`;

export const CREATE_QUOTE_PAYMENT_LINK_TPL = gql`
  mutation createQuotePaymentLink(
    $quoteId: Int!
    $totalAmount: Float!
    $isRequiredDeliveryDate: Boolean!
    $invoiceTemplate: String!
    $artworks: String!
    $ccEmail: String!
    $bccEmail: String!
    $isDebugMode: Boolean!
    $cardHolderName: String!
    $cardHolderLastName: String!
    $emailTplId: Int!
    $receivedEmailTplId: Int!
    $dontChancgeOrderStatus: Boolean!
  ) {
    createQuotePaymentLink(
      quoteId: $quoteId
      totalAmount: $totalAmount
      isRequiredDeliveryDate: $isRequiredDeliveryDate
      invoiceTemplate: $invoiceTemplate
      artworks: $artworks
      ccEmail: $ccEmail
      bccEmail: $bccEmail
      isDebugMode: $isDebugMode
      cardHolderName: $cardHolderName
      cardHolderLastName: $cardHolderLastName
      emailTplId: $emailTplId
      receivedEmailTplId: $receivedEmailTplId
      dontChancgeOrderStatus: $dontChancgeOrderStatus
    ) {
      id
      quoteId
      createdAt
      linkCheckoutSessionId
      linkExpirationAt
      linkHref
      updatedAt
      amount
      payload
      status
      linkGeneratorHref
      transactionId
      transactionCardType
      transactionCardNumberFirst
      transactionCardNumberLast
      transactionCardExpiry
      transactionType
      transactionAuth
      transactionRefNum
      transactionAt
    }
  }
`;

export const DELETE_QUOTE_PAYMENT_LINK_TPL = gql`
  mutation deleteQuotePaymentLinkTpl($tplId: String!) {
    deleteQuotePaymentLinkTpl(tplId: $tplId) {
      id
      quoteId
      createdAt
      linkCheckoutSessionId
      linkExpirationAt
      linkHref
      updatedAt
      amount
      payload
      status
      linkGeneratorHref
      transactionId
      transactionCardType
      transactionCardNumberFirst
      transactionCardNumberLast
      transactionCardExpiry
      transactionType
      transactionAuth
      transactionRefNum
      transactionAt
    }
  }
`;

export const SEND_QUOTE_CHECK_PAYMENT = gql`
  mutation sendQuoteCheckToCustomer($tplId: String!) {
    sendQuoteCheckToCustomer(tplId: $tplId) {
      id
      quoteId
      createdAt
      linkCheckoutSessionId
      linkExpirationAt
      linkHref
      updatedAt
      amount
      payload
      status
      linkGeneratorHref
      transactionId
      transactionCardType
      transactionCardNumberFirst
      transactionCardNumberLast
      transactionCardExpiry
      transactionType
      transactionAuth
      transactionRefNum
      transactionAt
    }
  }
`;

export const REFRESH_QUOTE_PAYMENT_LINK_TPL = gql`
  mutation refreshQuotePaymentLinkTpl($tplId: String!) {
    refreshQuotePaymentLinkTpl(tplId: $tplId) {
      id
      quoteId
      createdAt
      linkCheckoutSessionId
      linkExpirationAt
      linkHref
      updatedAt
      amount
      payload
      status
      linkGeneratorHref
      transactionId
      transactionCardType
      transactionCardNumberFirst
      transactionCardNumberLast
      transactionCardExpiry
      transactionType
      transactionAuth
      transactionRefNum
      transactionAt
    }
  }
`;

export const CHECK_PAYMENT_STATUS = gql`
  mutation checkPaymentStatus($tplId: String!, $transactionId: String!) {
    checkPaymentStatus(tplId: $tplId, transactionId: $transactionId) {
      id
      quoteId
      createdAt
      linkCheckoutSessionId
      linkExpirationAt
      linkHref
      updatedAt
      amount
      payload
      status
      linkGeneratorHref
      transactionId
      transactionCardType
      transactionCardNumberFirst
      transactionCardNumberLast
      transactionCardExpiry
      transactionType
      transactionAuth
      transactionRefNum
      transactionAt
    }
  }
`;

export const ADD_PAYMENT_TRANSACTION = gql`
  mutation addPaymentTransaction($quoteId: Int!, $transactionId: String!) {
    addPaymentTransaction(quoteId:$quoteId, transactionId: $transactionId) {
      id
      quoteId
      createdAt
      linkCheckoutSessionId
      linkExpirationAt
      linkHref
      updatedAt
      amount
      payload
      status
      linkGeneratorHref
      transactionId
      transactionCardType
      transactionCardNumberFirst
      transactionCardNumberLast
      transactionCardExpiry
      transactionType
      transactionAuth
      transactionRefNum
      transactionAt
    }
  }
`;
