import { convertDateToUSFormat } from "helpers/utils";
import { FC, useMemo } from "react";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";
import {
  ClearEditClientData
} from "../../../../types/Client";
import { ChildrenTable, TableHeading } from "../../../../types/Table";
import { Link } from "react-router-dom";
import EditClientReferralLog from "./EditClientReferralLog";
import EditClientReferralBalance from "./EditClientReferralBalance";

const EditClientReferral: FC<{
  clientId?: number,
  clientEditData: ClearEditClientData;
  setIsNeedUpdateClient: (value: boolean) => void;
}> = ({
  clientId,
  clientEditData,
  setIsNeedUpdateClient,
}) => {
  // --
  // -- Header
  // --
  const tableHeading = useMemo((): TableHeading => {
    return [
      {
        name: "client",
        label: "Client",
      },
      // {
      //   name: "createdAt",
      //   label: "Date",
      // },
      {
        name: "quoteAmount",
        label: "Quotes",
      },
      {
        name: "total",
        label: "Total",
      },      
    ];
  }, []);

  // --
  // -- Body
  // --
  const childrenForTable = useMemo((): ChildrenTable[] => !clientEditData?.refs?.length
    ? []
    : [
      ...clientEditData.refs.map(({ id, createdAt, quoteAmount, total, accountName }) => ({
        id,
        row: {
          client: (
            <Link to={`/clients/${id}`}>
              {accountName || `Client #${id}`}
            </Link>
          ),
          // createdAt: createdAt ? convertDateToUSFormat(createdAt) : '',
          quoteAmount,
          total,
        },        
      })),
      {
        id: 0,
        row: {
          client: '',
          // createdAt: '',
          quoteAmount: '',
          total: `Total: ${clientEditData.refs.reduce((acc, { total }) => acc + Number(total), 0)}`,
        },
      }
    ]
    , [clientEditData]);

  return (
    <section>
      <div className="row-fluid">
        <div className="box-2">
          <EditClientReferralBalance clientId={clientId} />
        </div>
      </div>
      <EditClientReferralLog clientId={clientId} />
      <TableWithHeaderNew
        header="Refferal clients"
        heading={tableHeading}
        countPages={childrenForTable.length ? 1 : 0}
        loading={false}
        children={childrenForTable}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateClient(true);
        }}
      />
    </section>
  );
};

export default EditClientReferral;
