import { FC, PropsWithChildren, ReactNode } from 'react';

const Card: FC<PropsWithChildren<{
  title?: ReactNode,
  actions?: ReactNode,
  className?: string,
  contentClassName?: string,
}>> = ({
  title,
  actions,
  className = '',
  contentClassName = '',
  children,
}) => (
  <div className={`form-card ${className}`}>
    {(!!title || !!actions) && (
      <div className="form-card__header">
        {!!title && <div className="label">{title}</div>}
        {!!actions && <div className="control">{actions}</div>}
      </div>
    )}    
    <div className={`form-card__content-list ${contentClassName}`}>
      {children}
    </div>
  </div>
);

export default Card;
