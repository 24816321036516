import { gql } from "@apollo/client";

export const SHIPPING_TRACK_QUERY = gql`
  query shippingTracks($quoteId: Int!) {
    shippingTracks(quoteId: $quoteId) {
      id
      number
      description
      createdAt
      updatedAt
      watch
      quote {
        id
      }
      user {
        id
        email
        userName
        firstName
        lastName
      }
    }
  }
`;
