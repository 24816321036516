import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ClearEditClientData,
  EditClientDataClientContact,
} from "../../../../types/Client";
import { FormField } from "../../../../types/Form";
import FormCard from "../../../../components/Form/El/FormCard";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { setNotification, userContextData } from "../../../../helpers/cache";
import { regExpPhone } from "../../../../helpers/RegExp";
import {
  isValidEmail,
  isValidNonEmpty,
  isValidNumber,
} from "../../../../helpers/Validation";
import { ChildrenTable, TableHeading } from "../../../../types/Table";
import {
  CREATE_CLIENT_CONTACT,
  UPDATE_CLIENT_CONTACT,
} from "../../Services/Mutations/Mutation";
import ClientDeleteModal from "./ClientDeleteModal";
import TableWithHeaderNew from "../../../../components/Table/TableWithHeaderNew";
import {
  CLIENT_ITEM_BYEMAIL_QUERY,
  ICLIENT_ITEM_BYEMAIL_QUERY,
} from "../../Services/Queries/ClientItemByEmailQuery";
import { NavLink } from "react-router-dom";
import {cloneDeep} from "lodash";
import EditClientClientInfoRefer from "./EditClientClientInfoRefer";

const EditClientClientInfoNew: FC<{
  clientEditData: ClearEditClientData;
  setNewClientData: (newClientData: ClearEditClientData) => void;
  clearClientContactData: EditClientDataClientContact;
  currentClientContactId: number;
  tabsAccess: any;
  defaultClientContactId: number;
  setDefaultClientContactId: (id: number) => void;
  setCurrentClientContactId: (id: number) => void;
  setIsNeedUpdateClient: (value: boolean) => void;
  clientId?: number;
}> = ({
  clientEditData,
  setNewClientData,
  clearClientContactData,
  currentClientContactId,
  tabsAccess,
  defaultClientContactId,
  setDefaultClientContactId,
  setCurrentClientContactId,
  setIsNeedUpdateClient,
  clientId,
}) => {
  const userDetails: any = useReactiveVar(userContextData);

  const [updateClientContact] = useMutation(UPDATE_CLIENT_CONTACT, {
    onError: (error) => {
      setNotification({ type: "ERROR", message: error.message });
    },
  });

  const [createClientContact] = useMutation(CREATE_CLIENT_CONTACT, {
    onError: (error) => {
      setNotification({ type: "ERROR", message: error.message });
    },
  });

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteClientContactId, setDeleteClientContactId] = useState<number>();
  const [isLoadingDeleteClientContact, setIsLoadingDeleteClientContact] =
    useState(false);
  const [isLoadingUpdateClientContact, setIsLoadingUpdateClientContact] =
    useState(false);
  const [
    isLoadingUpdateDefaultClientContactId,
    setIsLoadingUpdateDefaultClientContactId,
  ] = useState<number | undefined>();

  const clientInfoFormAccess = useMemo(
    () => tabsAccess?.clientInfo?.forms,
    [tabsAccess],
  );

  const companyInfoFormAccess = useMemo(
    () => clientInfoFormAccess?.companyInfo,
    [clientInfoFormAccess],
  );
  const referAccess = useMemo(
    () => clientInfoFormAccess?.refer,
    [clientInfoFormAccess],
  );
  const contactInfoFormAccess = useMemo(
    () => clientInfoFormAccess?.contactInfo,
    [clientInfoFormAccess],
  );

  const companyInfoFormAccessLevelFieldAccess = useMemo(
    () => companyInfoFormAccess?.fields?.accessLevel,
    [companyInfoFormAccess],
  );
  const companyInfoFormUserFieldAccess = useMemo(
    () => companyInfoFormAccess?.fields?.user,
    [companyInfoFormAccess],
  );
  const companyInfoFormAccountNameFieldAccess = useMemo(
    () => companyInfoFormAccess?.fields?.accountName,
    [companyInfoFormAccess],
  );
  const companyInfoFormCompanyFieldAccess = useMemo(
    () => companyInfoFormAccess?.fields?.company,
    [companyInfoFormAccess],
  );
  const companyInfoFormIndustryTagFieldAccess = useMemo(
    () => companyInfoFormAccess?.fields?.industryTag,
    [companyInfoFormAccess],
  );
  const companyInfoFormWebAddressFieldAccess = useMemo(
    () => companyInfoFormAccess?.fields?.webAddress,
    [companyInfoFormAccess],
  );

  const contactInfoFormFirstNameFieldAccess = useMemo(
    () => contactInfoFormAccess?.fields?.firstName,
    [contactInfoFormAccess],
  );
  const contactInfoFormLastNameFieldAccess = useMemo(
    () => contactInfoFormAccess?.fields?.lastName,
    [contactInfoFormAccess],
  );
  const contactInfoFormEmailFieldAccess = useMemo(
    () => contactInfoFormAccess?.fields?.email,
    [contactInfoFormAccess],
  );
  const contactInfoFormPhone1FieldAccess = useMemo(
    () => contactInfoFormAccess?.fields?.phone1,
    [contactInfoFormAccess],
  );
  const contactInfoFormPhone2FieldAccess = useMemo(
    () => contactInfoFormAccess?.fields?.phone2,
    [contactInfoFormAccess],
  );
  const contactInfoFormFaxFieldAccess = useMemo(
    () => contactInfoFormAccess?.fields?.fax,
    [contactInfoFormAccess],
  );
  const contactInfoFormJobTitleFieldAccess = useMemo(
    () => contactInfoFormAccess?.fields?.jobTitle,
    [contactInfoFormAccess],
  );
  const contactInfoFormBirthdayFieldAccess = useMemo(
    () => contactInfoFormAccess?.fields?.birthday,
    [contactInfoFormAccess],
  );
  const contactInfoFormSexFieldAccess = useMemo(
    () => contactInfoFormAccess?.fields?.gender,
    [contactInfoFormAccess],
  );
  const contactInfoFormNoteFieldAccess = useMemo(
    () => contactInfoFormAccess?.fields?.note,
    [contactInfoFormAccess],
  );

  const companyInfoFormFields = useMemo(
    (): FormField[] => [
      {
        name: "accessLevelCd",
        label: "Access Level",
        isIncorrectValue: !isValidNumber(clientEditData?.accessLevelCd),
        onChange: (value) => {
          if (typeof value === "number") {
            setNewClientData({
              ...clientEditData,
              accessLevelCd: value,
            });
          }
        },
        type: "select",
        subType: "clientAccessLevels",
        value: clientEditData?.accessLevelCd,
        accessedFields:companyInfoFormAccessLevelFieldAccess,
      },
      {
        name: "userId",
        label: "User",
        isIncorrectValue: !isValidNumber(clientEditData?.userId),
        onChange: (value) => {
          if (typeof value === "number") {
            setNewClientData({
              ...clientEditData,
              userId: value,
            });
          }
        },
        type: "select",
        subType: "users",
        value: clientEditData?.userId,
        accessedFields: companyInfoFormUserFieldAccess,
      },
      {
        name: "accountName",
        label: "Account name",
        isIncorrectValue: !isValidNonEmpty(clientEditData?.accountName),
        onChange: (value) => {
          if (typeof value === "string") {
            setNewClientData({
              ...clientEditData,
              accountName: value,
            });
          }
        },
        type: "input",
        value: clientEditData?.accountName,
        accessedFields: companyInfoFormAccountNameFieldAccess,
      },
      {
        name: "company",
        label: "Company",
        onChange: (value) => {
          if (typeof value === "string") {
            setNewClientData({
              ...clientEditData,
              company: value,
            });
          }
        },
        type: "input",
        value: clientEditData?.company,
        accessedFields:companyInfoFormCompanyFieldAccess,
      },
      {
        name: "industryTag",
        label: "Industry Tag",
        onChange: (value) => {
          if (typeof value === "number") {
            setNewClientData({
              ...clientEditData,
              industryTag: value,
            });
          }
        },
        type: "select",
        subType: "industryTags",
        value: clientEditData?.industryTag,
        accessedFields: companyInfoFormIndustryTagFieldAccess,
      },
      {
        name: "webAdress",
        label: "Web address",
        onChange: (value) => {
          if (typeof value === "string") {
            setNewClientData({
              ...clientEditData,
              webAdress: value,
            });
          }
        },
        type: "input",
        value: clientEditData?.webAdress,
        accessedFields:companyInfoFormWebAddressFieldAccess,
      },
    ],
    [
      clientEditData,
      companyInfoFormWebAddressFieldAccess,
      companyInfoFormIndustryTagFieldAccess,
      companyInfoFormCompanyFieldAccess,
      companyInfoFormAccountNameFieldAccess,
      companyInfoFormUserFieldAccess,
      companyInfoFormAccessLevelFieldAccess,
      setNewClientData,
    ],
  );

  const currentClientContactIndex: number = useMemo(() => {
    const { clientContacts } = clientEditData;

    if (!clientContacts || !clientContacts.length) return 0;
    
    const searchIndex = clientContacts.findIndex((clientContact) =>
      currentClientContactId
        ? clientContact.id === currentClientContactId
        : !clientContact.id,
    );

    return searchIndex === -1 ? clientContacts.length : searchIndex;
  }, [clientEditData, currentClientContactId]);

  const currentClientContactData = useMemo(() => {
    const { clientContacts } = clientEditData;
    return clientContacts?.[currentClientContactIndex]
      ? { ...clientContacts[currentClientContactIndex] }
      : clearClientContactData;
  }, [currentClientContactIndex, clientEditData, clearClientContactData]);

  const setModifiedClientContactData = useCallback(
    (modifiedClientContactData: Partial<EditClientDataClientContact>) => {
      const { clientContacts } = clientEditData;

      let currentClientContactsData = clientContacts ? cloneDeep(clientContacts) : [];

      if (!currentClientContactsData?.length) {
        currentClientContactsData = [
          {
            ...clearClientContactData,
          },
        ];
      }

      currentClientContactsData[currentClientContactIndex] = {
        ...clearClientContactData,
        ...currentClientContactsData[currentClientContactIndex],
        ...modifiedClientContactData,
      };

      setNewClientData({
        ...clientEditData,
        clientContacts: currentClientContactsData,
      });
    },
    [clientEditData, currentClientContactIndex, setNewClientData],
  );

  const contactInfoFormFields = useMemo((): FormField[] => {
    if (!currentClientContactData) return [];

    return [
      {
        name: "firstName",
        label: "First Name",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedClientContactData({
              firstName: value,
            });
          }
        },
        type: "input",
        value: currentClientContactData?.firstName,
        isIncorrectValue: !isValidNonEmpty(currentClientContactData?.firstName),
        accessedFields:contactInfoFormFirstNameFieldAccess,
      },
      {
        name: "lastName",
        label: "Last Name",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedClientContactData({
              lastName: value,
            });
          }
        },
        type: "input",
        value: currentClientContactData?.lastName,
        accessedFields:contactInfoFormLastNameFieldAccess,
      },
      {
        name: "email",
        label: "Email",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedClientContactData({
              email: value,
            });

            !!currentClientContactData.email &&
            isValidEmail(currentClientContactData.email) &&
            fetchExistClients({
              variables: {
                email: currentClientContactData.email,
              },
            });
          }
        },
        type: "input",
        value: currentClientContactData?.email,
        isIncorrectValue: !isValidEmail(currentClientContactData?.email),
        accessedFields:contactInfoFormEmailFieldAccess,
      },
      {
        name: "phone1",
        label: "Phone 1",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedClientContactData({
              phone1: value,
            });
          }
        },
        type: "input",
        value: currentClientContactData?.phone1,
        accessedFields:contactInfoFormPhone1FieldAccess,
        inputOptions: {
          pattern: regExpPhone,
        },
      },
      {
        name: "phone2",
        label: "Phone 2",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedClientContactData({
              phone2: value,
            });
          }
        },
        type: "input",
        value: currentClientContactData?.phone2,
        accessedFields: contactInfoFormPhone2FieldAccess,
        inputOptions: {
          pattern: regExpPhone,
        },
      },
      {
        name: "fax",
        label: "Fax",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedClientContactData({
              fax: value,
            });
          }
        },
        type: "input",
        value: currentClientContactData?.fax,
        accessedFields: contactInfoFormFaxFieldAccess,
      },
      {
        name: "jobTitle",
        label: "Job Title",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedClientContactData({
              jobTitle: value,
            });
          }
        },
        type: "input",
        value: currentClientContactData?.jobTitle,
        accessedFields: contactInfoFormJobTitleFieldAccess,
      },
      {
        name: "birthday",
        label: "Birthday",
        onChange: (value) => {
          return;
        },
        type: "date",
        value: undefined,
        dateOptions: {
          startDate: currentClientContactData?.birthday ||  null,
          onStartDateChange: (value) => {
            setModifiedClientContactData({
              birthday: value ? value : null,
            });
          },
        },
        accessedFields:contactInfoFormBirthdayFieldAccess,
      },
      {
        name: "genders",
        label: "Gender",
        onChange: (value) => {
          if (typeof value === "number") {
            setModifiedClientContactData({
              sex: value,
            });
          }
        },
        type: "select",
        subType: "genders",
        value: currentClientContactData?.sex,
        accessedFields: contactInfoFormSexFieldAccess,
      },
      {
        name: "note",
        label: "Note",
        onChange: (value) => {
          if (typeof value === "string") {
            setModifiedClientContactData({
              note: value,
            });
          }
        },
        type: "input",
        inputOptions: {
          isTextarea: true,
          rowCount: 2,
        },
        value: currentClientContactData?.note,
        accessedFields: contactInfoFormNoteFieldAccess,
      },
    ];
  }, [
    currentClientContactData,
    contactInfoFormNoteFieldAccess,
    contactInfoFormSexFieldAccess,
    contactInfoFormBirthdayFieldAccess,
    contactInfoFormJobTitleFieldAccess,
    contactInfoFormFaxFieldAccess,
    contactInfoFormPhone2FieldAccess,
    contactInfoFormPhone1FieldAccess,
    contactInfoFormEmailFieldAccess,
    contactInfoFormLastNameFieldAccess,
    contactInfoFormFirstNameFieldAccess,
    setModifiedClientContactData,
  ]);

  const tableHeading = useMemo(
    (): TableHeading => [
      {
        name: "buttons",
        label: "Edit",
      },
      {
        name: "name",
        label: "Name",
      },
      {
        name: "email",
        label: "Email",
      },
      {
        name: "phone1",
        label: "Phone_1",
      },
      {
        name: "phone2",
        label: "Phone_2",
      },
      {
        name: "jobTitle",
        label: "Job Title",
      },
      {
        name: "fax",
        label: "Fax",
      },
    ],
    [],
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [dataCollection, setDataCollection] = useState<
    EditClientDataClientContact[]
  >([]);
  const [countPages, setCountPages] = useState(0);

  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  useEffect(() => {
    const { clientContacts } = clientEditData;
    const countItems = clientContacts?.length || 0;

    setCountPages(Math.ceil(countItems / pageSize));
    setDataCollection(
      clientContacts?.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize - 1,
      ) || [],
    );
  }, [clientEditData, pageSize, currentPage]);

  const onUpdateDefaultClientContactId = useCallback(async (id: number) => {
    setIsLoadingUpdateDefaultClientContactId(id);

    await updateClientContact({
      variables: {
        id: id,
        isDefault: true,
      },
    })
      .then((res) => {
        const { data } = res;

        if (data?.updateClientContact?.id) {
          setNotification([
            {
              type: "SUCCESS",
              message: "Client contact successfully set as default contact.",
            },
          ]);

          setDefaultClientContactId(id);

          setIsNeedUpdateClient(true);
        }
      })
      .finally(() => {
        setIsLoadingUpdateDefaultClientContactId(undefined);
      });
  }, []);

  const onDeleteClientContactId = useCallback(async () => {
    setIsLoadingDeleteClientContact(true);
    await updateClientContact({
      variables: {
        id: deleteClientContactId,
        isDelete: true,
      },
    })
      .then((res) => {
        const { data } = res;

        if (data?.updateClientContact?.id) {
          setNotification([
            {
              type: "SUCCESS",
              message: "Client contact successfully deleted.",
            },
          ]);

          setIsNeedUpdateClient(true);
        }
      })
      .finally(() => {
        setIsLoadingDeleteClientContact(false);
        setDeleteClientContactId(0);
      });
  }, [deleteClientContactId]);

  useEffect(() => {
    setShowDeleteModal(!!deleteClientContactId);
  }, [deleteClientContactId]);

  useEffect(() => {
    if (!showDeleteModal && deleteClientContactId) {
      setDeleteClientContactId(0);
    }
  }, [showDeleteModal]);

  const indexClientContactWithoutId = useMemo(() => {
    const { clientContacts } = clientEditData;

    return clientContacts?.findIndex((clientContact) => !clientContact.id);
  }, [clientEditData]);

  const onCreateNewClientContact = useCallback(() => {
    setCurrentClientContactId(0);
  }, [clientEditData]);

  const onSaveClientContact = useCallback(async () => {
    setIsLoadingUpdateClientContact(true);

    const onSaveCallback = async () => {
      return currentClientContactId
        ? await updateClientContact({
            variables: {
              clientId,
              id: currentClientContactId,
              ...currentClientContactData,
            },
          })
        : await createClientContact({
            variables: {
              clientId,
              ...currentClientContactData,
            },
          });
    };
    await onSaveCallback()
      .then((res) => {
        const { data } = res;

        if (
          data?.[
            currentClientContactId
              ? "updateClientContact"
              : "createClientContact"
          ]?.id
        ) {
          setIsNeedUpdateClient(true);

          setNotification([
            {
              type: "SUCCESS",
              message: `Client contact successfully ${
                currentClientContactId ? "updated" : "saved"
              }.`,
            },
          ]);
        }
      })
      .finally(() => {
        setIsLoadingUpdateClientContact(false);
      });
  }, [clientId, currentClientContactId, currentClientContactData]);

  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenForTable: ChildrenTable[] = [];

    const isDefaultContact = (id: number) => id === defaultClientContactId;

    dataCollection.forEach((dataCollectionItem) => {
      const { id, firstName, lastName, email, phone1, phone2, jobTitle, fax } =
        dataCollectionItem;

      let name = firstName;

      if (lastName) {
        name.concat(` ${lastName}`);
      }

      currentChildrenForTable.push({
        id: id || 0,
        row: {
          buttons: (
            <div className="btn-group">
              <button
                className="btn btn-small btn-success"
                onClick={() => {
                  setCurrentClientContactId(id || 0);
                }}
              >
                <i className="icon-edit icon-medium" />
              </button>
              <button
                className="btn btn-small btn-warning"
                disabled={(!!id && isDefaultContact(id)) || !id}
                onClick={() => {
                  setDeleteClientContactId(id || 0);
                }}
              >
                <i className="icon-trash-empty icon-medium" />
              </button>
              <button
                className="btn btn-small btn-blue"
                onClick={() => {
                  onUpdateDefaultClientContactId(id || 0);
                }}
                disabled={!id || (!!id && isDefaultContact(id))}
              >
                <i
                  className={`${
                    !!id && isLoadingUpdateDefaultClientContactId === id
                      ? "inline-block animate-spin icon-spin4"
                      : !!id && isDefaultContact(id)
                        ? "icon-bookmark"
                        : "icon-bookmark-empty"
                  } icon-medium`}
                />
              </button>
            </div>
          ),
          name: name || "-",
          email: email || "-",
          phone1: phone1 || "-",
          phone2: phone2 || "-",
          jobTitle: jobTitle || "-",
          fax: fax || "-",
        },
        additionalClassRow: id && isDefaultContact(id) ? "selected" : "",
      });
    });

    return currentChildrenForTable;
  }, [dataCollection]);

  const [
    fetchExistClients,
    {
      data: existClients,
      loading: existClientLoading,
      refetch: refetchExistClient,
    },
  ] = useLazyQuery<ICLIENT_ITEM_BYEMAIL_QUERY>(CLIENT_ITEM_BYEMAIL_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const [additionalErrors, setAdditionalErrors] =
    useState<{ [K in keyof EditClientDataClientContact]?: ReactNode }>();

  useEffect(() => {
    const client = existClients?.clientsByEmail?.[0];

    client && client.id !== clientId
      ? setAdditionalErrors({
          email: (
            <>
              This email already exists
              <br />
              Owner:{" "}
              {client?.user?.userName ||
                client?.user?.email ||
                `id: ${client?.user?.id}`}
              <br />
              Client:{" "}
              <NavLink to={`/clients/${client?.id}`} className="underline">
                {client?.company || `id: ${client?.id}`}
              </NavLink>
            </>
          ),
        })
      : setAdditionalErrors((prevState) => ({
          ...prevState,
          email: undefined,
        }));
  }, [existClients?.clientsByEmail]);

  return (
    <section>
      <div className="row-fluid">
        {companyInfoFormAccess && (
          <FormCard
            name="company-info"
            formFields={[companyInfoFormFields]}
            className="box-4"
            label="Company Info"
          />
        )}

        {contactInfoFormLastNameFieldAccess && (
          <FormCard
            name="contact-info"
            formFields={[contactInfoFormFields]}
            className="box-4"
            label="Contact Info"
            additionalButtons={[
              {
                label: currentClientContactId ? "Update" : "Save",
                icon: isLoadingUpdateClientContact
                  ? "inline-block animate-spin icon-spin4"
                  : "icon-floppy",
                color: "success",
                name: "save",
                disabled:
                  isLoadingUpdateClientContact ||
                  !currentClientContactData.firstName ||
                  !isValidEmail(currentClientContactData.email),
                onClick: () => {
                  onSaveClientContact();
                },
                hidden: !clientId,
              },
              {
                label: "New Contact",
                color: "default",
                name: "createNewClientContact",
                onClick: () => {
                  onCreateNewClientContact();
                },
                hidden: !clientId,
                disabled:
                  indexClientContactWithoutId !== -1 || !currentClientContactId,
              },
            ]}
            additionalErrors={additionalErrors}
          />
        )}

        {referAccess && (
          <EditClientClientInfoRefer
            clientId={clientEditData?.referId ?? undefined}
            onChange={(referId) => setNewClientData({
              ...clientEditData,
              referId: referId ?? null,
            })}
          />
        )}
      </div>
      <TableWithHeaderNew
        header="Client contacts"
        heading={tableHeading}
        currentItemsPerPage={pageSize}
        currentPage={currentPage}
        countPages={countPages}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        loading={false}
        children={childrenForTable}
        setIsNeedUpdateTableData={() => {
          setIsNeedUpdateClient(true);
        }}
      />
      {showDeleteModal && (
        <ClientDeleteModal
          setShowDeleteModal={setShowDeleteModal}
          deleteTitle={"Delete client contact"}
          loading={isLoadingDeleteClientContact}
          onDelete={() => {
            onDeleteClientContactId();
          }}
        />
      )}
    </section>
  );
};

export default EditClientClientInfoNew;
