export const CONFIG = {
  orderProduct: {
    recordType: {
      refferalBonus: 'referral-bonus',      
    },
    referralBonusDescription: 'Referral Bonus',
  },
  referralLog: {
    status: {
      0: 'Unknown',      
      1: 'Applied',
      2: 'Blocked',
    }
  }

};