import { useQuery } from "@apollo/client";
import { IClientReferralBalance } from "components/model/IClientReferralBalance";
import { IResponseCollection } from "components/model/IResponseCollection";
import { FC } from "react";
import { CLIENT_REFERRAL_BALANCE } from "../../Services/Queries/Queries";
import Card from "components/Card/Card";

const EditClientReferralBalance: FC<{ clientId?: number }> = ({ clientId }) => {

  const { data: referralBalance, loading, refetch } = 
    useQuery<IResponseCollection<'clientReferralBalance', IClientReferralBalance>>(CLIENT_REFERRAL_BALANCE, {
      variables: {
        clientId,
      }, 
      skip: !clientId,
    });

  return (
    <Card
      title="Referral Balance"
      contentClassName="flex justify-center p-4 gap-2 items-end"
    >
      {loading
        ? <i className="inline-block animate-spin icon-spin4" />
        : (
          <>
            <div className="text-2xl flex flex-col">
              {Number(referralBalance?.clientReferralBalance?.balance)?.toFixed(2)}
              <div className="text-xs flex justify-start">Active</div>
            </div>
            {!!Number(referralBalance?.clientReferralBalance?.balanceBlocked) && (
              <div className="text-sx text-gray-600 flex flex-col justify-center">
                + {(-1 * Number(referralBalance?.clientReferralBalance?.balanceBlocked))?.toFixed(2)}
                <div className="text-xs flex justify-end">Blocked</div>
              </div>
            )}
          </>
      )}
    </Card>
  );
};

export default EditClientReferralBalance;
