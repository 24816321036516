import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  PriceDataList,
  ProductEditData,
  ProductEditDataWithIndex,
  ProductWithCategory,
} from "../../../../types/Product";
import { ChildrenTable, TableHeading } from "../../../../types/Table";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { setNotification, userContextData } from "../../../../helpers/cache";
import TableNew from "../../../../components/Table/TableNew";
import Input from "../../../../components/Form/El/Input";
import { regExpNumber } from "../../../../helpers/RegExp";
import { PRODUCT_WITH_CATEGORY } from "../../Services/Queries/Queries";
import { QuoteEditData } from "../../../../types/Quote";
import { cloneDeep } from "lodash";
import EditQuoteQuoteInfoTableItemDescription from "./EditQuoteQuoteInfoTableItemDescription";
import CheckboxNew from "../../../../components/Form/El/CheckboxNew";
import EditQuoteReferralForm from "./EditQuoteReferralForm";
import { IResponseCollection } from "components/model/IResponseCollection";
import { IClientReferralBalance } from "components/model/IClientReferralBalance";
import { CLIENT_REFERRAL_BALANCE } from "modules/Clients/Services/Queries/Queries";
import { CONFIG } from "config";

const EditQuoteQuoteInfoTable: FC<{
  quoteEditData: QuoteEditData;
  accessEditProducts: boolean;
  accessDisableForSuppliers: boolean;
  accessShowSupplierAccess: boolean;
  products: ProductEditData[];
  isLoadingData?: boolean;
  setQuoteEditData:  React.Dispatch<React.SetStateAction<QuoteEditData>>;
  stateTax: number | null;
}> = ({
  accessEditProducts,
  accessDisableForSuppliers,
  accessShowSupplierAccess,
  products,
  isLoadingData,
  setQuoteEditData,
  quoteEditData,
  stateTax,
}) => {
  const [productPricesList, setProductPricesList] = useState<
    Record<number, PriceDataList | number>
  >({});
  const [
    productPricesListProductIdNumberUpdated,
    setProductPricesListProductIndexUpdated,
  ] = useState<number | undefined>(undefined);

  const [getProductsWithCategory, { loading: isLoadingProductsWithCategory }] =
    useLazyQuery(PRODUCT_WITH_CATEGORY, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      onCompleted: (data) => {
        if (data?.productsWithCategory) {
          setProductsWithCategory(data.productsWithCategory);
        }
      },
      notifyOnNetworkStatusChange: true,
    });

  const onGetProductWithCategory = useCallback(async () => {
    await getProductsWithCategory();
  }, [getProductsWithCategory]);

  useEffect(() => {
    onGetProductWithCategory();
  }, []);

  const [productsWithCategory, setProductsWithCategory] = useState<
    ProductWithCategory[]
  >([]);

  const setProductValue = useCallback(
    (index: number, data: Partial<ProductEditData>) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        const tmpProducts = tmpEditData.orderProducts;

        if (tmpProducts[index]) {
          tmpProducts[index] = {
            ...tmpProducts[index],
            ...data,
          };
        }

        return tmpEditData;
      })
    },
    [setQuoteEditData],
  );

  const tableHeading = useMemo((): TableHeading => {
    if (accessEditProducts) {
      return [
        {
          name: "buttons",
          isSmall: true,
          label: "",
          minWidth: "50px",
        },
        ...(accessDisableForSuppliers || accessShowSupplierAccess
          ? [{
            label: "for Suppliers",
            name: "for-suppliers",
            isSmall: true,
            minWidth: "80px",
          }] : []
        ),
        {
          label: "Quantity",
          name: "quantity-edit",
          minWidth: "80px",
        },
        
        {
          label: "Item",
          name: "dsc-edit",
        },
        {
          label: "Unit Price",
          name: "unit-price",
          isSmall: true,
          minWidth: "100px",
        },
        {
          label: "Amount",
          name: "amount",
          isSmall: true,
          minWidth: "100px",
        },
      ];
    }
    return [
      ...(accessDisableForSuppliers || accessShowSupplierAccess
        ? [{
          label: "for Suppliers",
          name: "for-suppliers",
          isSmall: true,
          minWidth: "80px",
        }] : []
      ),
      {
        label: "Quantity",
        name: "quantity-show",
        minWidth: "80px",
      },
      {
        label: "Product",
        name: "dsc-show",
      },
    ];
  }, [accessEditProducts]);

  const userDetails: any = useReactiveVar(userContextData);

  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);

  const productWithIndex = useMemo(
    (): ProductEditDataWithIndex[] =>
      products.map((el, index) => ({
        ...el,
        index,
      })),
    [products],
  );

  const countPages = useMemo((): number => {
    const productCount = productWithIndex.length;

    if (!productCount || !pageSize) {
      return 1;
    }

    return 1
  }, [pageSize, productWithIndex]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [pageSize]);

  const productsPerPage = useMemo(
    /*() =>
      productWithIndex.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize,
      ),*/
      () => productWithIndex,
    [productWithIndex, pageSize, currentPage],
  );

  useEffect(() => {
    productWithIndex.forEach((currentProduct) => {
      if (
        productPricesListProductIdNumberUpdated !== undefined &&
        currentProduct.index === productPricesListProductIdNumberUpdated &&
        currentProduct.productId &&
        ((!!currentProduct.productOptionString &&
          currentProduct.priceTableId) ||
          !currentProduct.priceTableId)
      ) {
        setProductValue(currentProduct.index, {
          unitPrice: getNewUnitPriceFromTable(
            currentProduct.quantity ? +currentProduct.quantity : 1,
            currentProduct.priceTableId,
            currentProduct,
          ),
        });

        setProductPricesListProductIndexUpdated(undefined);
      }
    });
  }, [
    productPricesList,
    productWithIndex,
    productPricesListProductIdNumberUpdated,
  ]);

  const getNewUnitPriceFromTable = useCallback(
    (
      quantity: number,
      currentPriceTableId: number | null,
      product: ProductEditDataWithIndex,
    ) => {
      let currentPriceTable = undefined;
      let currentUnitPrice: number | undefined = undefined;
      let currentPriceTableList: PriceDataList | number | undefined = undefined;

      if (typeof product.productId === "number") {
        currentPriceTableList = productPricesList[product.productId];
        currentPriceTable =
          typeof currentPriceTableId === "number" &&
          currentPriceTableList !== undefined &&
          typeof currentPriceTableList !== "number"
            ? (currentPriceTable = currentPriceTableList?.[currentPriceTableId])
            : undefined;
      }

      if (
        currentPriceTableList !== undefined &&
        typeof currentPriceTableList === "number"
      ) {
        currentUnitPrice = currentPriceTableList;
      }

      const firstOption = product.productOptionString?.split("_")[product.currentMainProductIndexInOptionString || 0];

      if (currentPriceTable && firstOption) {
        const currentPriceTableKeys = Object.keys(currentPriceTable);
        const currentKey =
          currentPriceTableKeys.find((el, index) => {
            const parsedEl = +el;
            const nextEl = currentPriceTableKeys[index + 1];

            return (
              quantity === parsedEl ||
              (quantity !== parsedEl &&
                quantity > parsedEl &&
                quantity < +nextEl) ||
              (quantity < parsedEl && !(quantity > +nextEl))
            );
          }) || currentPriceTableKeys.slice(-1)[0];

        currentUnitPrice = currentKey
          ? currentPriceTable[+currentKey]?.find(
              (el) => el.optionId === +firstOption,
            )?.unitPrize
          : 0;
      }
      return currentUnitPrice || 0;
    },
    [productPricesList],
  );

  const getNewAmount = useCallback((quantity: number, unitPrice: number) => {
    if (quantity && unitPrice) {
      return (quantity * unitPrice).toFixed(2);
    }

    return "";
  }, []);

  // --
  // -- Referral Balance  
  // --
  // console.log('quoteEditData.orderProducts = ', quoteEditData.orderProducts);
  

  // -- Get referral Balance
  const { 
    data: referralBalance,
    loading: isReferralBalanceLoading,
    refetch: refetchReferralBalace,
  } = useQuery<IResponseCollection<'clientReferralBalance', IClientReferralBalance>>(CLIENT_REFERRAL_BALANCE, {
    variables: { clientId: quoteEditData.client.id }, 
    skip: !quoteEditData?.client?.id,
  }); 

  useEffect(() => { 
    isLoadingProductsWithCategory && refetchReferralBalace();
  }, [isLoadingProductsWithCategory])

  const getReferralBonusOrderProductIndx = (): number => (quoteEditData?.orderProducts || [])
    .findIndex(({ recordType }) => recordType === CONFIG.orderProduct.recordType.refferalBonus);    

  const appliedReferralBonuses = (): number => {
    const indx = getReferralBonusOrderProductIndx();
    const refBonusOrderProduct = quoteEditData?.orderProducts?.[indx];
    return refBonusOrderProduct
      ? -1 * (Number(refBonusOrderProduct?.quantity) || 0) * (Number(refBonusOrderProduct?.unitPrice) || 0)
      : 0;
  }

  // -- 
  const applyBonuses = (amount: number) => {
    if (!amount) return;

    const refBonusOrderProductIndx = getReferralBonusOrderProductIndx();
    const newRefBonusOrderProduct = {
      productId: null,
      productOptionString: null,
      priceTableId: null,
      unitPrice: amount * -1,
      quantity: 1,
      description: CONFIG.orderProduct.referralBonusDescription,
      isNewProduct: true,
      recordType: CONFIG .orderProduct.recordType.refferalBonus,
    }

    setQuoteEditData(prevState => {
      const tmpEditData = cloneDeep(prevState);
      const tmpProducts = tmpEditData.orderProducts;

      refBonusOrderProductIndx >= 0
        ? (tmpProducts[refBonusOrderProductIndx] = newRefBonusOrderProduct)
        : tmpProducts.push(newRefBonusOrderProduct);

      return tmpEditData;
    })
  }

  const deleteBonuses = () => {
    const refBonusOrderProductIndx = getReferralBonusOrderProductIndx();
    if (refBonusOrderProductIndx < 0) return;

    setQuoteEditData(prevState => {
      const tmpEditData = cloneDeep(prevState);
      const tmpProducts = tmpEditData.orderProducts;

      tmpProducts.splice(refBonusOrderProductIndx, 1);

      return tmpEditData;
    })

    // const refBonusOrderProductIndx = getReferralBonusOrderProductIndx();
    // const newRefBonusOrderProduct = {
    //   productId: null,
    //   productOptionString: null,
    //   priceTableId: null,
    //   unitPrice: amount * -1,
    //   quantity: 1,
    //   description: CONFIG.orderProduct.referralBonusDescription,
    //   isNewProduct: true,
    //   recordType: CONFIG.orderProduct.recordType.refferalBonus,
    // }

    // setQuoteEditData(prevState => {
    //   const tmpEditData = cloneDeep(prevState);
    //   const tmpProducts = tmpEditData.orderProducts;

    //   refBonusOrderProductIndx >= 0
    //     ? (tmpProducts[refBonusOrderProductIndx] = newRefBonusOrderProduct)
    //     : tmpProducts.push(newRefBonusOrderProduct);

    //   return tmpEditData;
    // })
  }


  const childrenForTable = useMemo((): ChildrenTable[] => {
    let currentChildrenTable: ChildrenTable[] = [];

    let totalAmount = 0;

    productsPerPage.forEach((product, index) => {
      const isReferralBonus = product?.recordType === CONFIG.orderProduct.recordType.refferalBonus;

      const amount = () => {
        if (product.amount) {
          return product.amount;
        }
        if (product.quantity && product.unitPrice) {
          return getNewAmount(+product.quantity, +product.unitPrice);
        }

        return "";
      };
// console.log('product = ', product);

      totalAmount = totalAmount + (+amount() || 0);
      currentChildrenTable.push({
        id: product.productId || 0,
        row: {
          buttons: (
            <div className="btn-group flex gap-2">
              <button
                className="btn btn-mini btn-warning"
                onClick={() => {
                  setQuoteEditData(prevState => {
                    const tmpEditData = cloneDeep(prevState);
                    const tmpProducts = tmpEditData.orderProducts;

                    tmpProducts.splice(product.index, 1);

                    return tmpEditData;
                  })
                }}
              >
                <i className="icon-trash-empty icon-medium" />{" "}
              </button>
            </div>
          ),
          ["for-suppliers"]: isReferralBonus
            ? <div />
            : (
              <div style={{ width: '1em', margin: '0 auto' }}>
                <CheckboxNew
                  className="flex justify-center mx-auto"
                  disabled={!accessDisableForSuppliers}
                  value={!!product.isForSupplier}
                  onChange={() => {
                    setProductValue(product.index, { isForSupplier: !product.isForSupplier })
                  }}
                  name="isForSupplier"
                />
              </div>
            ),
          ["quantity-show"]: isReferralBonus
            ? <div />
            : (
              <div>
                {product.quantity || "-"}
              </div>
            ),
          ["dsc-show"]: <div>{product.description || "-"}</div>,
          ["quantity-edit"]: isReferralBonus
            ? <div />
            : (
              <Input
                value={product.quantity || ""}
                onChange={(value) => {
                  let currentValue = value;

                  if (typeof currentValue === "string") {
                    currentValue = +currentValue;
                  }

                  if (!isNaN(currentValue)) {
                    setProductValue(product.index, {
                      amount: undefined,
                      quantity: currentValue,
                      unitPrice: getNewUnitPriceFromTable(
                        currentValue,
                        product.priceTableId,
                        product,
                      ),
                    });
                  }
                }}
                name={`product-quantity-${product.productId || 0}=${index}`}
                pattern={regExpNumber}
                type="number"
              />
            ),
          ["dsc-edit"]: isReferralBonus
            ? (
              <Input
                value={product?.description || ''}
                onChange={(value) => {                  
                  setProductValue(product.index, {
                    description: String(value),
                  });
                }}
                name={`input-product-description-${Math.random()}`}
                type={"text"}
                
              />
            ) : (
              <EditQuoteQuoteInfoTableItemDescription
                product={product}
                productsWithCategory={productsWithCategory}
                isLoadingProductsWithCategory={isLoadingProductsWithCategory}
                setProductValue={(index, data) => {
                  const tmpData = cloneDeep(data);
                  const currentKeys = Object.keys(tmpData);

                  const isChangedProductOptionString = currentKeys.includes('productOptionString');
                  const isChangedCurrentMainProductIndexInOptionString = currentKeys.includes('productOptionString');

                  const isChangedMainProduct = () => {
                    if (!isChangedProductOptionString && !isChangedCurrentMainProductIndexInOptionString)
                    {
                      return false;
                    }

                    const prevCurrentMainProductIndexInOptionString = product.currentMainProductIndexInOptionString || 0;
                    const currentCurrentMainProductIndexInOptionString = isChangedCurrentMainProductIndexInOptionString && typeof tmpData.currentMainProductIndexInOptionString === 'number' ? tmpData.currentMainProductIndexInOptionString : prevCurrentMainProductIndexInOptionString;

                    const prevProductOptionString = product.productOptionString || "";
                    const currentProductOptionString = isChangedProductOptionString ? (tmpData.productOptionString || "") : prevProductOptionString;

                    const prevMainOption = prevProductOptionString.split("_")[prevCurrentMainProductIndexInOptionString];
                    const currentMainOption = currentProductOptionString.split("_")[currentCurrentMainProductIndexInOptionString];

                    return prevMainOption !== currentMainOption
                  }

                  if (
                    currentKeys.includes("priceTableId") ||
                      isChangedMainProduct()
                  ) {
                    tmpData.unitPrice = getNewUnitPriceFromTable(
                      product.quantity ? +product.quantity : 1,
                      tmpData.priceTableId || product.priceTableId || null,
                      {
                        ...product,
                        ...tmpData,
                      },
                    );
                  }

                  if (product.quantity && tmpData.unitPrice) {
                    tmpData.amount = getNewAmount(
                      +product.quantity,
                      +tmpData.unitPrice,
                    );
                  }

                  setProductValue(index, tmpData);
                }}
                setProductPricesList={(
                  index: number | undefined,
                  productId: number,
                  data: PriceDataList | number,
                ) => {
                  setProductPricesList((prevState) => ({
                    ...prevState,
                    [productId]: data,
                  }));
                  setProductPricesListProductIndexUpdated(index);
                }}
              />
            ),
          ["unit-price"]: isReferralBonus
            ? <div />
            : (
              <Input
                value={
                  product.unitPrice !== null &&
                  typeof +product.unitPrice === "number"
                    ? product.unitPrice
                    : ""
                }
                onChange={(value) => {
                  setProductValue(product.index, {
                    amount: undefined,
                    unitPrice: value,
                  });
                }}
                name={`input-product-unit-price-${Math.random()}`}
                type={"number"}
                
              />
            ),
          ["amount"]: isReferralBonus
            ? (
              <div className="flex justify-center">
                {product.amount !== undefined ? product.amount : amount()}
              </div>
            ) : (
              <Input
                value={product.amount !== undefined ? product.amount : amount()}
                onChange={(value) => {
                  let currentValue = value;
                  let data: Partial<ProductEditData> = {};

                  if (typeof currentValue === "string") {
                    currentValue = +currentValue;
                  }

                  data.amount = !!currentValue ? value : "";

                  if (
                    !!product.quantity &&
                    !!product.unitPrice &&
                    !isNaN(currentValue)
                  ) {
                    data.unitPrice = (currentValue / +product.quantity).toFixed(
                      2,
                    );
                  }

                  setProductValue(product.index, data);
                }}
                name={`input-product-amount-${Math.random()}`}
                type={"number"}
              />
            ),
        },
      });
    });

    let totalTax = 0;

    if (stateTax) {
      totalTax = totalAmount * (stateTax / 100);
    }

    const totalAmountWithTax = totalAmount + totalTax;

    currentChildrenTable.push({
      id: 0,
        blockDraggable: true,
      row: {
        ["buttons"]: (
          <div className="btn-group">
            <button
              className="btn btn-mini btn-success"
              onClick={() => {
                setQuoteEditData(prevState => {
                  const tmpEditData = cloneDeep(prevState);
                  const tmpProducts = tmpEditData.orderProducts;

                  tmpProducts.push({
                    productId: null,
                    productOptionString: null,
                    priceTableId: null,
                    unitPrice: null,
                    quantity: null,
                    description: null,
                    isNewProduct: true,
                  });

                  return tmpEditData;
                })
              }}
            >
              <i className="icon-plus icon-medium" />
            </button>
          </div>
        ),
        ["for-suppliers"]: <div />,
        ["quantity-edit"]: <div />,
        ["dsc-edit"]: (
          referralBalance?.clientReferralBalance?.balance || referralBalance?.clientReferralBalance.balanceBlocked
            ? <EditQuoteReferralForm 
              balance={referralBalance.clientReferralBalance.balance}
              balanceBlocked={referralBalance.clientReferralBalance.balanceBlocked * -1}
              applied={appliedReferralBonuses()}
              applyBonuses={applyBonuses}
              deleteBonuses={deleteBonuses}
              loading={isReferralBalanceLoading}
              clientId={quoteEditData?.client?.id}
            /> : <div />          
        ),
        ["unit-price"]: (
          <Fragment>
            {typeof stateTax === "number" && (
              <CheckboxNew
                value={!!quoteEditData.withoutTax}
                onChange={(value) => {
                  setQuoteEditData(prevState => {
                    const tmpEditData = cloneDeep(prevState);

                    tmpEditData.withoutTax = value;

                    return tmpEditData;
                  })
                }}
                name="without-tax-checkbox"
                label="Without tax"
              />
            )}
            <CheckboxNew
              value={!!quoteEditData.withoutTotal}
              onChange={(value) => {
                setQuoteEditData(prevState => {
                  const tmpEditData = cloneDeep(prevState);

                  tmpEditData.withoutTotal = value;

                  return tmpEditData;
                })
              }}
              name="without-total-checkbox"
              label="Without total"
            />
          </Fragment>
        ),
        ["amount"]: (
          <Fragment>
            <div>
              {typeof stateTax === "number" && !quoteEditData.withoutTax && (
                <div className="text-xs">{`Tax: ${totalTax.toFixed(2)}`}</div>
              )}
            </div>
            <div>
              {!quoteEditData.withoutTotal && (
                <div className="text-xs">{`Total: ${(stateTax &&
                !quoteEditData.withoutTax
                  ? totalAmountWithTax
                  : totalAmount
                ).toFixed(2)}`}</div>
              )}
            </div>
          </Fragment>
        ),
      },
    });

    return currentChildrenTable;
  }, [
    productsPerPage,
    productsWithCategory,
    quoteEditData,
    isLoadingProductsWithCategory,
    products,
    setQuoteEditData,
    setProductValue,
    stateTax,
    referralBalance,
  ]);


    const rearangeProductArray = useCallback(
        (oldIndex: number, newIndex: number) => {
          setQuoteEditData(prevState => {
            const oldIndexWithPage = oldIndex + pageSize * (currentPage - 1);
            const newIndexWithPage = newIndex + pageSize * (currentPage - 1);

            const tmpEditData = cloneDeep(prevState);
            const cloneProducts = tmpEditData.orderProducts;
            const draggingItemContent = products[oldIndexWithPage];

            cloneProducts.splice(oldIndexWithPage, 1);
            cloneProducts.splice(newIndexWithPage, 0, {...draggingItemContent, id: draggingItemContent.id});

            return tmpEditData;
          })
        },
        [products, pageSize, currentPage, setQuoteEditData, productWithIndex, productsPerPage],
    );

  return (
    <TableNew
      heading={tableHeading}
      setIsNeedUpdateTableData={() => {}}
      currentPage={1}
      countPages={1}
      /*setCurrentPage={setCurrentPage}*/
      /*setPageSize={setPageSize}*/
      /*currentItemsPerPage={pageSize}*/
      children={childrenForTable}
      draggable={!!accessEditProducts}
      onDragEnter={rearangeProductArray}
    />
  );
};

export default EditQuoteQuoteInfoTable;
