import { useLazyQuery } from "@apollo/client";
import { cloneDeep } from "lodash";
import React, {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NavLink } from "react-router-dom";
import CheckboxNew from "../../../../components/Form/El/CheckboxNew";
import FormCard from "../../../../components/Form/El/FormCard";
import Input from "../../../../components/Form/El/Input";
import SelectCombobox from "../../../../components/Form/El/SelectCombobox";
import SelectFromServerData from "../../../../components/Form/El/SelectFromServerData";
import TableNew from "../../../../components/Table/TableNew";
import { setNotification } from "../../../../helpers/cache";
import {
  isValidEmail,
  isValidNonEmpty,
  isValidNumber,
} from "../../../../helpers/Validation";
import { EditClientDataClientContact } from "../../../../types/Client";
import { FormFields, FormSelectGroupOption } from "../../../../types/Form";
import { QuoteEditData } from "../../../../types/Quote";
import { ChildrenTable, TableHeading } from "../../../../types/Table";
import { QUICK_QUOTE_ADDITIONAL_INFOS_COLLECTION_QUERY } from "../../../AdminDashboard/Services/Queries/Queries";
import {
  CLIENT_ITEM_BYEMAIL_QUERY,
  ICLIENT_ITEM_BYEMAIL_QUERY,
} from "../../../Clients/Services/Queries/ClientItemByEmailQuery";
import { DOWNLOAD_PDF } from "../../Services/Queries/Queries";
import EditQuoteQuoteInfoSearchBox from "./EditQuoteQuoteInfoSearchBox";
import EditQuoteQuoteInfoTable from "./EditQuoteQuoteInfoTable";

const EditQuoteQuoteInfo: FC<{
  quoteEditData: QuoteEditData;
  setQuoteEditData:  React.Dispatch<React.SetStateAction<QuoteEditData>>;
  setIsNeedUpdateQuote: () => void;
  setClientId: (id: number) => void;
  tabQuoteInfoAccess: any;
  hasEditAndDeleteAccess: boolean;
  isLoadingData?: boolean;
  setDeleteId: (data: { type: string; id: number }) => void;
  onUpdateQuotePartial: (data: QuoteEditData) => Promise<void>;
  isValidForms: boolean;
  clientContactsList: FormSelectGroupOption[];
  setClientContactId: (id: number) => void;
  billingAddressesList: FormSelectGroupOption[];
  setBillingAddressId: (id: number) => void;
  shippingAddressesList: FormSelectGroupOption[];
  setShippingAddressId: (id: number) => void;
  stateTax: number | null;
  onChangeTax: (value: number | null) => void;
  defaultClientContactId: number | null;
  createdNewClientContact: boolean;
  defaultBillingAddressId: number | null;
  createdNewBillingAddress: boolean;
  defaultShippingAddressId: number | null;
  createdNewShippingAddress: boolean;
  roleName?: string;
  clientChanged: boolean;
}> = ({
  quoteEditData,
  setQuoteEditData,
  setIsNeedUpdateQuote,
  setClientId,
  tabQuoteInfoAccess,
  hasEditAndDeleteAccess,
  isLoadingData,
  setDeleteId,
  onUpdateQuotePartial,
  isValidForms,
  clientContactsList,
  setClientContactId,
  billingAddressesList,
  setBillingAddressId,
  shippingAddressesList,
  setShippingAddressId,
  stateTax,
  onChangeTax,
  defaultClientContactId,
  createdNewClientContact,
  roleName,
  defaultBillingAddressId,
  defaultShippingAddressId,
  createdNewBillingAddress,
  createdNewShippingAddress,
  clientChanged
}) => {
  const userId = useMemo(() => quoteEditData.userId, [quoteEditData]);

  const tabQuoteInfoFieldsAccess = useMemo(
    () => tabQuoteInfoAccess?.fields,
    [tabQuoteInfoAccess],
  );
  const tabQuoteInfoFormsAccess = useMemo(
    () => tabQuoteInfoAccess?.forms,
    [tabQuoteInfoAccess],
  );
  const tabQuoteInfoButtonsAccess = useMemo(
    () => tabQuoteInfoAccess?.buttons,
    [tabQuoteInfoAccess],
  );
  const tabQuoteInfoHeaderAccess = useMemo(
    () => tabQuoteInfoAccess?.header,
    [tabQuoteInfoAccess],
  );

  const tabQuoteInfoFormsCompanyAccess = useMemo(
    () => tabQuoteInfoFormsAccess?.companyInfo,
    [tabQuoteInfoFormsAccess],
  );
  const tabQuoteInfoFormsFieldsCompanyAccess = useMemo(
    () => tabQuoteInfoFormsCompanyAccess?.fields,
    [tabQuoteInfoFormsCompanyAccess],
  );

  const tabQuoteInfoFormsBillingAddressAccess = useMemo(
    () => tabQuoteInfoFormsAccess?.clientBillingAddress,
    [tabQuoteInfoFormsAccess],
  );
  const tabQuoteInfoFormsBillingAddressFieldsAccess = useMemo(
    () => tabQuoteInfoFormsBillingAddressAccess?.fields,
    [tabQuoteInfoFormsBillingAddressAccess],
  );

  const tabQuoteInfoFormsShippingAddressAccess = useMemo(
    () => tabQuoteInfoFormsAccess?.clientShippingAddress,
    [tabQuoteInfoFormsAccess],
  );
  const tabQuoteInfoFormsShippingAddressFieldsAccess = useMemo(
    () => tabQuoteInfoFormsShippingAddressAccess?.fields,
    [tabQuoteInfoFormsShippingAddressAccess],
  );

  const tabQuoteInfoFormsOrderProductsAccess = useMemo(
    () => tabQuoteInfoFormsAccess?.orderProducts,
    [tabQuoteInfoFormsAccess],
  );

  const [
    getAdditionalInfo,
    { data: quickQuoteLineData, loading: isLoadingQuickQuoteLineData },
  ] = useLazyQuery(QUICK_QUOTE_ADDITIONAL_INFOS_COLLECTION_QUERY, {
    onError: (error) => {
      setNotification([
        {
          type: "ERROR",
          message: `${error?.message}`,
        },
      ]);
    },
    notifyOnNetworkStatusChange: true,
  });

  const [downloadPDF] = useLazyQuery(DOWNLOAD_PDF, {
    onError: (error) => {
      setNotification([
        {
          type: "ERROR",
          message: `${error?.message}`,
        },
      ]);
    },
    notifyOnNetworkStatusChange: true,
  });

  const updateUserId = useCallback(
    (id: number) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        tmpEditData.userId = id;

        return tmpEditData;
      })

    },
    [setQuoteEditData],
  );

  const onUpdateQuote = useCallback(async () => {
    setIsLoadingUpdateQuoteModuleServerData(true);
    await onUpdateQuotePartial(quoteEditData).finally(() => {
      setIsLoadingUpdateQuoteModuleServerData(false);
    });
  }, [quoteEditData, onUpdateQuotePartial]);

  const leadSource = useMemo(() => quoteEditData.leadSource, [quoteEditData]);
  const updateLeadSource = useCallback(
    (id: number) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        tmpEditData.leadSource = id;

        return tmpEditData;
      })
    },
    [setQuoteEditData],
  );

  const leadSourceStatus = useMemo(
    () => quoteEditData.leadSourceStatus,
    [quoteEditData],
  );
  const updateLeadSourceStatus = useCallback(
    (id: number) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        tmpEditData.leadSourceStatus = id;

        return tmpEditData;
      })
    },
    [setQuoteEditData],
  );

  const [
    isLoadingUpdateQuoteModuleServerData,
    setIsLoadingUpdateQuoteModuleServerData,
  ] = useState(false);

  const paymentStatus = useMemo(() => quoteEditData.status, [quoteEditData]);
  const updatePaymentStatus = useCallback(
    (id: number) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        tmpEditData.status = id;

        return tmpEditData;
      })
    },
    [setQuoteEditData],
  );

  // --
  // -- Set shipped email templates if the status is shipped (6)
  // --
  const [shippedEmailTplId, setShippedEmailTplId] = useState<number>(0);
  useEffect(() => {
    setQuoteEditData(prevState => {
      const tmpEditData = cloneDeep(prevState);
      tmpEditData.shippedEmailTplId = shippedEmailTplId;

      return tmpEditData;
    })
  }, [shippedEmailTplId]);

  const note = useMemo(() => quoteEditData?.note || "", [quoteEditData]);
  const updateNote = useCallback(
    (value: string) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        tmpEditData.note = value;

        return tmpEditData;
      })
    },
    [setQuoteEditData],
  );

  const [isManualChangedClientContactId, setIsManualChangedClientContactId] =
    useState(false);
  const [isManualChangedShippingAddressId, setIsManualShippingAddressId] =
    useState(false);
  const [isManualChangedBillingAddressId, setIsManualChangedBillingAddressId] =
    useState(false);

  const client = useMemo(() => quoteEditData.client, [quoteEditData]);

  const clientContact = useMemo(() => client.clientContacts, [client]);
  const clientShippingAddresses = useMemo(
    () => client.clientShippingAddresses,
    [client],
  );
  const clientBillingAddresses = useMemo(
    () => client.clientBillingAddresses,
    [client],
  );

  const [isLoadingDownloadQuote, setIsLoadingDownloadQuote] = useState(false);
  const [isLoadingDownloadInvoice, setIsLoadingDownloadInvoice] = useState(false);
  const [isLoadingDownloadPacking, setIsLoadingDownloadPacking] = useState(false);

  const link = useMemo(() => document.createElement("a"), []);

  useEffect(() => {
    getAdditionalInfo();
  }, [quoteEditData.useAdditionalLine]);

  useEffect(() => {
    if (!quoteEditData.useAdditionalLine || isLoadingData) {
      return;
    }

    if (
      quoteEditData.quoteAdditionalRows &&
      !!quoteEditData.quoteAdditionalRows.length
    ) {
      return;
    }

    if (!quickQuoteLineData || isLoadingQuickQuoteLineData) return;

    let newQuickQuoteAdditionalData: QuoteEditData["quoteAdditionalRows"] =
      quickQuoteLineData.quickQuoteAdditionalInfos;
    newQuickQuoteAdditionalData = newQuickQuoteAdditionalData.map((el) => ({
      description: el.description,
      quickQuoteAdditionalInfo:
        typeof el.id === "number" ? { id: el.id } : null,
    }));

    newQuickQuoteAdditionalData.push(
      ...Array.from({ length: 3 }, (_, i) => ({
        description: "",
        quickQuoteAdditionalInfo: null,
      })),
    );

    setQuoteEditData(prevState => {
      const tmpEditData = cloneDeep(prevState);
      tmpEditData.quoteAdditionalRows = newQuickQuoteAdditionalData;

      return tmpEditData;
    })

  }, [
    quoteEditData.quoteAdditionalRows,
    quoteEditData.useAdditionalLine,
    isLoadingData,
    quickQuoteLineData,
    isLoadingQuickQuoteLineData,
  ]);

  const tableHeadingForQuickQuoteAdditionalLineTable = useMemo(
    (): TableHeading => [
      {
        name: "checkbox",
        label: "",
        minWidth: "0px",
      },
      {
        name: "description",
        label: "Description",
      },
    ],
    [],
  );

  const childrenForQuickQuoteAdditionalLineTable =
    useMemo((): ChildrenTable[] => {
      const childrenForTable: ChildrenTable[] = [];

      quoteEditData.quoteAdditionalRows.forEach((el, index) => {
        childrenForTable.push({
          id: el.id || 0,
          row: {
            ["checkbox"]: (
              <Fragment>
                {!!el.id && (
                  <CheckboxNew
                    value={true}
                    onChange={() => {}}
                    name={`checkbox-${el.id}`}
                  />
                )}
              </Fragment>
            ),
            ["description"]: (
              <Fragment>
                <Input
                  value={el.description || ""}
                  disabled={
                    typeof el?.quickQuoteAdditionalInfo?.id === "number"
                  }
                  onChange={(value) => {
                    setQuoteEditData(prevState => {
                      const tmpEditData = cloneDeep(prevState);
                      const tmpCurrentAdditionalLines = cloneDeep(
                        tmpEditData.quoteAdditionalRows,
                      );
                      tmpCurrentAdditionalLines[index].description =
                        value.toString();
                      tmpEditData.quoteAdditionalRows = tmpCurrentAdditionalLines;

                      return tmpEditData;
                    })
                  }}
                  name={`input-description-${el?.id || Math.random()}`}
                />
              </Fragment>
            ),
          },
        });
      });

      return childrenForTable;
    }, [quoteEditData.quoteAdditionalRows, setQuoteEditData]);

  const onDownloadPDF = useCallback(
    async (type: "quote" | "invoice" | "packing", template: string) => {
      const setLoading = (isActive: boolean) => {
        if (type === "quote") {
          setIsLoadingDownloadQuote(isActive);
        }

        if (type === "invoice") {
          setIsLoadingDownloadInvoice(isActive);
        }

        if (type === "packing") {
          setIsLoadingDownloadPacking(isActive);
        }
      };

      if (template !== "umd" && template !== "ep") return;

      setLoading(true);

      await downloadPDF({
        variables: {
          quoteId: quoteEditData.id,
          type,
          template,
        },
      })
        .then((res) => {
          const { data } = res;

          if (!data) return;

          const { downloadPdf } = data;

          if (!downloadPdf) return;

          const { file, fileName } = downloadPdf;

          if (!file || !fileName) return;

          link.href = `data:application/pdf;base64,${file}`;
          link.download = fileName;
          link.click();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [],
  );

  const updateClient = useCallback(
    (data: Partial<QuoteEditData["client"]>) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        tmpEditData.client = {
          ...tmpEditData.client,
          ...data
        };

        return tmpEditData;
      })
    },
    [setQuoteEditData, client],
  );

  const updateClientContact = useCallback(
    (data: Partial<QuoteEditData["client"]["clientContacts"]>) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        tmpEditData.client = {
          ...tmpEditData.client,
          clientContacts: {
            ...tmpEditData.client.clientContacts,
            ...data
          }
        };

        return tmpEditData;
      })
    },
    [setQuoteEditData],
  );

  const updateShippingAddress = useCallback(
    (data: Partial<QuoteEditData["client"]["clientShippingAddresses"]>) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        tmpEditData.client = {
          ...tmpEditData.client,
          clientShippingAddresses: {
            ...tmpEditData.client.clientShippingAddresses,
            ...data
          }
        };

        return tmpEditData;
      })
    },
    [setQuoteEditData],
  );

  const updateBillingAddress = useCallback(
    (data: Partial<QuoteEditData["client"]["clientBillingAddresses"]>) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        tmpEditData.client = {
          ...tmpEditData.client,
          clientBillingAddresses: {
            ...tmpEditData.client.clientBillingAddresses,
            ...data
          }
        };

        return tmpEditData;
      })
    },
    [setQuoteEditData],
  );

  const [
    fetchExistClients,
    {
      data: existClients,
      loading: existClientLoading,
      refetch: refetchExistClient,
    },
  ] = useLazyQuery<ICLIENT_ITEM_BYEMAIL_QUERY>(CLIENT_ITEM_BYEMAIL_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (clientChanged) {
      setIsManualChangedBillingAddressId(false);
      setIsManualChangedBillingAddressId(false);
      setIsManualChangedClientContactId(false);
    }

  }, [clientChanged]);

  const [additionalErrors, setAdditionalErrors] =
    useState<{ [K in keyof EditClientDataClientContact]?: ReactNode }>();

  useEffect(() => {
    const clientExist = existClients?.clientsByEmail?.[0];

    clientExist && clientExist.id !== client.id
      ? setAdditionalErrors({
          email: (
            <>
              This email already exists
              <br />
              Owner:{" "}
              {clientExist?.user?.userName ||
                clientExist?.user?.email ||
                `id: ${clientExist?.user?.id}`}
              <br />
              Client:{" "}
              <NavLink to={`/clients/${clientExist?.id}`} className="underline">
                {clientExist?.company || `id: ${clientExist?.id}`}
              </NavLink>
            </>
          ),
        })
      : setAdditionalErrors((prevState) => ({
          ...prevState,
          email: undefined,
        }));
  }, [existClients?.clientsByEmail]);

  const billingAddressFormField = useMemo(
    (): FormFields => [
      {
        name: "billingAddressId",
        label: "Change Billing",
        onChange: (value) => {
          if (client?.clientBillingAddresses.id && !value) {
            setIsManualChangedBillingAddressId(true);
          }

          if (typeof value === "number") {
            setBillingAddressId(value);
          }
        },
        type: "select",
        selectOptions: {
          optionsGroup: billingAddressesList,
        },
        value: client?.clientBillingAddresses.id || 0,
        accessedFields:
          tabQuoteInfoFormsBillingAddressFieldsAccess?.changeBilling,
      },
      {
        name: "address",
        label: "Address",
        onChange: (value) => {
          if (typeof value === "string") {
            updateBillingAddress({
              address: value,
            });
          }
        },
        type: "input",
        value: clientBillingAddresses.address,
        accessedFields: tabQuoteInfoFormsBillingAddressFieldsAccess?.address,
      },
      {
        name: "address2",
        label: "Address 2",
        onChange: (value) => {
          if (typeof value === "string") {
            updateBillingAddress({
              address2: value,
            });
          }
        },
        type: "input",
        value: clientBillingAddresses.address2,
        accessedFields: tabQuoteInfoFormsBillingAddressFieldsAccess?.address2,
      },
      {
        name: "city",
        label: "City",
        onChange: (value) => {
          if (typeof value === "string") {
            updateBillingAddress({
              city: value,
            });
          }
        },
        type: "input",
        value: clientBillingAddresses.city,
        accessedFields: tabQuoteInfoFormsBillingAddressFieldsAccess?.city,
      },
      {
        name: "state",
        label: "State",
        onChange: (value) => {
          if (typeof value === "string") {
            updateBillingAddress({
              state: value,
            });
          }
        },
        type: "select",
        subType: "states",
        value: clientBillingAddresses.state,
        accessedFields: tabQuoteInfoFormsBillingAddressFieldsAccess?.state,
      },
      {
        name: "country",
        label: "Country",
        onChange: (value) => {
          if (typeof value === "number") {
            updateBillingAddress({
              countryId: value,
            });
          }
        },
        type: "select",
        subType: "countries",
        value: clientBillingAddresses.countryId,
        accessedFields: tabQuoteInfoFormsBillingAddressFieldsAccess?.country,
      },
      {
        name: "zip",
        label: "Zip",
        onChange: (value) => {
          if (typeof value === "string") {
            updateBillingAddress({
              zip: value,
            });
          }
        },
        type: "input",
        value: clientBillingAddresses.zip,
        accessedFields: tabQuoteInfoFormsBillingAddressFieldsAccess?.zip,
      },
      {
        name: "attentionTo",
        label: "Attention To",
        onChange: (value) => {
          if (typeof value === "string") {
            updateBillingAddress({
              attentionTo: value,
            });
          }
        },
        type: "input",
        value: clientBillingAddresses.attentionTo,
        accessedFields:
          tabQuoteInfoFormsBillingAddressFieldsAccess?.attentionTo,
      },
    ],
    [
      client,
      clientBillingAddresses,
      updateClient,
      billingAddressesList,
      tabQuoteInfoFormsBillingAddressFieldsAccess,
      updateBillingAddress,
      setBillingAddressId,
    ],
  );

  const shippingAddressFormField = useMemo(
    (): FormFields => [
      {
        name: "shippingAddressId",
        label: "Change Shipping",
        onChange: (value) => {
          if (client?.clientShippingAddresses.id && !value) {
            setIsManualShippingAddressId(true);
          }

          if (typeof value === "number") {
            setShippingAddressId(value);
          }
        },
        type: "select",
        selectOptions: {
          optionsGroup: shippingAddressesList,
        },
        value: client?.clientShippingAddresses.id || 0,
        accessedFields:
          tabQuoteInfoFormsShippingAddressFieldsAccess?.changeShipping,
      },
      {
        name: "address",
        label: "Address",
        onChange: (value) => {
          if (typeof value === "string") {
            updateShippingAddress({
              address: value,
            });
          }
        },
        type: "input",
        value: clientShippingAddresses.address,
        accessedFields: tabQuoteInfoFormsShippingAddressFieldsAccess?.address,
      },
      {
        name: "address2",
        label: "Address 2",
        onChange: (value) => {
          if (typeof value === "string") {
            updateShippingAddress({
              address2: value,
            });
          }
        },
        type: "input",
        value: clientShippingAddresses.address2,
        accessedFields: tabQuoteInfoFormsShippingAddressFieldsAccess?.address2,
      },
      {
        name: "city",
        label: "City",
        onChange: (value) => {
          if (typeof value === "string") {
            updateShippingAddress({
              city: value,
            });
          }
        },
        type: "input",
        value: clientShippingAddresses.city,
        accessedFields: tabQuoteInfoFormsShippingAddressFieldsAccess?.city,
      },
      {
        name: "state",
        label: "State",
        onChange: (value) => {
          if (typeof value === "string") {
            updateShippingAddress({
              state: value,
            });
            setQuoteEditData(prevState => {
              const tmpEditData = cloneDeep(prevState);
              tmpEditData.withoutTax = true;
              return tmpEditData;
            })
          }
        },
        stateOptions: {
          onChangeTax: (value) => {            
            if (value !== stateTax) {
              onChangeTax(value);
            }
          },
        },
        type: "select",
        subType: "states",
        value: clientShippingAddresses.state,
        accessedFields: tabQuoteInfoFormsShippingAddressFieldsAccess?.state,
      },
      {
        name: "country",
        label: "Country",
        onChange: (value) => {
          if (typeof value === "number") {
            updateShippingAddress({
              countryId: value,
            });
          }
        },
        type: "select",
        subType: "countries",
        value: clientShippingAddresses.countryId,
        accessedFields: tabQuoteInfoFormsShippingAddressFieldsAccess?.country,
      },
      {
        name: "zip",
        label: "Zip",
        onChange: (value) => {
          if (typeof value === "string") {
            updateShippingAddress({
              zip: value,
            });
          }
        },
        type: "input",
        value: clientShippingAddresses.zip,
        accessedFields: tabQuoteInfoFormsShippingAddressFieldsAccess?.zip,
      },
      {
        name: "attentionTo",
        label: "Attention To",
        onChange: (value) => {
          if (typeof value === "string") {
            updateShippingAddress({
              attentionTo: value,
            });
          }
        },
        type: "input",
        value: clientShippingAddresses.attentionTo,
        accessedFields:
          tabQuoteInfoFormsShippingAddressFieldsAccess?.attentionTo,
      },
    ],
    [
      client,
      updateClient,
      shippingAddressesList,
      tabQuoteInfoFormsShippingAddressFieldsAccess,
      updateShippingAddress,
      clientShippingAddresses,
      setShippingAddressId,
    ],
  );

  useEffect(() => {
    if (isLoadingData) return;

    const clientContactsOptions = clientContactsList?.[0]?.options || [];
    const isNeedSetNewClientContactId =
      !!clientContactsOptions.length &&
      !client?.clientContacts.id &&
      !!client?.id &&
      !isManualChangedClientContactId &&
      !createdNewClientContact &&
      (!quoteEditData.id || clientChanged);

    if (!isNeedSetNewClientContactId) {
      return;
    }

    if (defaultClientContactId) {
      setClientContactId(defaultClientContactId);
    }
  }, [
    clientContactsList,
    client?.clientContacts.id,
    isLoadingData,
    client?.id,
    defaultClientContactId,
  ]);

  useEffect(() => {
    if (isLoadingData) return;

    const shippingAddressOptions = shippingAddressesList?.[0]?.options || [];

    const isNeedSetNewShippingAddressId =
      !!shippingAddressOptions.length &&
      !client?.clientShippingAddresses.id &&
      !!client?.id &&
      !isManualChangedShippingAddressId &&
      !createdNewShippingAddress  &&
      (!quoteEditData.id || clientChanged);

    if (!isNeedSetNewShippingAddressId) {
      return;
    }

    if (defaultShippingAddressId) {
      setShippingAddressId(defaultShippingAddressId);
    }
  }, [
    shippingAddressesList,
    client?.clientShippingAddresses.id,
    isLoadingData,
    client?.id,
    defaultShippingAddressId,
  ]);

  useEffect(() => {
    if (isLoadingData) return;

    const billingAddressOptions = billingAddressesList?.[0]?.options || [];

    const isNeedSetNewBillingAddressId =
      !!billingAddressOptions.length &&
      !client?.clientBillingAddresses.id &&
      !!client?.id &&
      !isManualChangedBillingAddressId &&
      !createdNewBillingAddress  &&
      (!quoteEditData.id || clientChanged);

    if (!isNeedSetNewBillingAddressId) {
      return;
    }

    if (defaultBillingAddressId) {
      setBillingAddressId(defaultBillingAddressId);
    }
  }, [
    billingAddressesList,
    client?.clientBillingAddresses.id,
    isLoadingData,
    client?.id,
    defaultBillingAddressId,
  ]);

  const companyInfoFormFields = useMemo(
    (): FormFields => [
      {
        name: "accessLevelCd",
        label: "Access Level",
        isIncorrectValue: !isValidNumber(client?.accessLevelCd),
        onChange: (value) => {
          if (typeof value === "number") {
            updateClient({
              accessLevelCd: value,
            });
          }
        },
        type: "select",
        subType: "clientAccessLevels",
        value: client?.accessLevelCd,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.accessLevel,
      },
      {
        name: "userId",
        label: "User",
        isIncorrectValue: !isValidNumber(client?.userId),
        onChange: (value) => {
          if (typeof value === "number") {
            updateClient({
              userId: value,
            });
          }
        },
        type: "select",
        subType: "users",
        value: client?.userId,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.user,
      },
      {
        name: "accountName",
        label: "Account name",
        isIncorrectValue: !isValidNonEmpty(client?.accountName),
        onChange: (value) => {
          if (typeof value === "string") {
            updateClient({
              accountName: value,
            });
          }
        },
        type: "input",
        value: client?.accountName,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.accountName,
      },
      {
        name: "company",
        label: "Company",
        onChange: (value) => {
          if (typeof value === "string") {
            updateClient({
              company: value,
            });
          }
        },
        type: "input",
        value: client?.company,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.company,
      },
      {
        name: "industryTag",
        label: "Industry Tag",
        onChange: (value) => {
          if (typeof value === "number") {
            updateClient({
              industryTag: value,
            });
          }
        },
        type: "select",
        subType: "industryTags",
        value: client?.industryTag,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.industryTag,
      },
      {
        name: "webAddress",
        label: "Web address",
        onChange: (value) => {
          if (typeof value === "string") {
            updateClient({
              webAddress: value,
            });
          }
        },
        type: "input",
        value: client?.webAddress,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.webAddress,
      },
      {
        name: "clientContactId",
        label: "Change Client Contact",
        onChange: (value) => {
          if (client?.clientContacts.id && !value) {
            setIsManualChangedClientContactId(true);
          }
          if (typeof value === "number") {
            setClientContactId(value);
          }
        },
        type: "select",
        selectOptions: {
          optionsGroup: clientContactsList,
        },
        value: client?.clientContacts.id || 0,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.clientContact,
      },
      {
        name: "firstName",
        label: "First Name",
        onChange: (value) => {
          if (typeof value === "string") {
            updateClientContact({
              firstName: value,
            });
          }
        },
        type: "input",
        value: client?.clientContacts?.firstName,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.firstName,
        isIncorrectValue: !isValidNonEmpty(client?.clientContacts?.firstName),
      },
      {
        name: "lastName",
        label: "Last Name",
        onChange: (value) => {
          if (typeof value === "string") {
            updateClientContact({
              lastName: value,
            });
          }
        },
        type: "input",
        value: client?.clientContacts?.lastName,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.lastName,
      },
      {
        name: "email",
        label: "Email",
        onChange: (value) => {
          if (typeof value === "string") {
            updateClientContact({
              email: value,
            });

            !!clientContact.email &&
              isValidEmail(clientContact.email) &&
              fetchExistClients({
                variables: {
                  email: value,
                },
              });
          }
        },
        type: "input",
        value: client?.clientContacts?.email,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.email,
        isIncorrectValue:
          !isValidNonEmpty(client?.clientContacts?.email) ||
          !isValidEmail(client?.clientContacts?.email),
      },
      {
        name: "phone1",
        label: "Phone 1",
        onChange: (value) => {
          if (typeof value === "string") {
            updateClientContact({
              phone1: value,
            });
          }
        },
        type: "input",
        value: client?.clientContacts?.phone1,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.phone1,
      },
      {
        name: "phone2",
        label: "Phone 2",
        onChange: (value) => {
          if (typeof value === "string") {
            updateClientContact({
              phone2: value,
            });
          }
        },
        type: "input",
        value: client?.clientContacts?.phone2,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.phone2,
      },
      {
        name: "fax",
        label: "Fax",
        onChange: (value) => {
          if (typeof value === "string") {
            updateClientContact({
              fax: value,
            });
          }
        },
        type: "input",
        value: client?.clientContacts?.fax,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.fax,
      },
      {
        name: "jobTitle",
        label: "Job Title",
        onChange: (value) => {
          if (typeof value === "string") {
            updateClientContact({
              jobTitle: value,
            });
          }
        },
        type: "input",
        value: client?.clientContacts?.jobTitle,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.jobTitle,
      },
      {
        name: "birthday",
        label: "Birthday",
        onChange: () => {},
        dateOptions: {
          startDate: client?.clientContacts?.birthday || null,
          onStartDateChange: (value) => {
            updateClientContact({
              birthday: value,
            });
          },
        },
        type: "date",
        value: undefined,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.birthday,
      },
      {
        name: "sex",
        label: "Gender",
        onChange: (value) => {
          if (typeof value === "number") {
            updateClientContact({
              sex: value,
            });
          }
        },
        type: "select",
        subType: "genders",
        value: client?.clientContacts?.sex,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.gender,
      },
      {
        name: "note",
        label: "Note",
        onChange: (value) => {
          if (typeof value === "string") {
            updateClientContact({
              note: value,
            });
          }
        },
        type: "input",
        inputOptions: {
          rowCount: 2,
          isTextarea: true,
        },
        value: client?.clientContacts?.note,
        accessedFields: tabQuoteInfoFormsFieldsCompanyAccess?.note,
      },
    ],
    [
      client,
      updateClient,
      clientContactsList,
      updateClientContact,
      tabQuoteInfoFormsFieldsCompanyAccess,
    ],
  );

  useEffect(() => {
    if (!!quoteEditData.client.id && !quoteEditData.id) {
      updateLeadSource(4);
    }
  }, [quoteEditData.client.id, quoteEditData.id]);

  return (
    <section>
      {!!tabQuoteInfoHeaderAccess?.fields?.searchBox?.editable && (
        <EditQuoteQuoteInfoSearchBox
          value={quoteEditData.client.id || 0}
          onChange={(id) => {
            if (id) {
              setClientId(id);
            }
          }}
        />
      )}
      {!!tabQuoteInfoFieldsAccess && (
        <div className="row-fluid edit-quote__selects">
          {!!tabQuoteInfoFieldsAccess?.userId && (
            <SelectFromServerData
              value={userId}
              onChange={(value) => {
                if (typeof value === "number") {
                  updateUserId(value);
                }
              }}
              type="users"
              label="User ID"
              className="box-3"
              disabled={!tabQuoteInfoFieldsAccess?.userId?.editable}
            />
          )}
          {!!tabQuoteInfoFieldsAccess?.leadSource && (
            <SelectFromServerData
              value={leadSource}
              onChange={(value) => {
                if (typeof value === "number") {
                  updateLeadSource(value);
                }
              }}
              type="leadSource"
              label="Lead source"
              className="box-3"
              disabled={
                !tabQuoteInfoFieldsAccess?.leadSource?.editable ||
                (!!quoteEditData.client.id && !quoteEditData.id)
              }
            />
          )}
          {!!tabQuoteInfoFieldsAccess?.leadStatus && (
            <SelectFromServerData
              value={leadSourceStatus}
              onChange={(value) => {
                if (typeof value === "number") {
                  updateLeadSourceStatus(value);
                }
              }}
              type="configQuotesLeadStatuses"
              label="Lead status"
              className="box-3"
              disabled={!tabQuoteInfoFieldsAccess?.leadStatus?.editable}
            />
          )}
          {!!tabQuoteInfoFieldsAccess?.status && (
            <div className="box-3 row-fluid">
              <SelectFromServerData
                value={paymentStatus}
                onChange={(value) => {
                  if (typeof value === "number") {
                    updatePaymentStatus(value);
                  }
                }}
                type="configQuotesStatuses"
                label="Status"
                // className={paymentStatus === 6 ? 'box-5' : 'box-12'}
                disabled={
                  !(
                    tabQuoteInfoFieldsAccess?.status?.editable &&
                    hasEditAndDeleteAccess
                  )
                }
                isNewQuote={!quoteEditData?.id}
              />
              {/* {paymentStatus === 6 && (
                <SelectFromServerData
                  value={shippedEmailTplId}
                  onChange={(value) => typeof value === "number" && setShippedEmailTplId(() => Number(value))                    
                  }
                  type="quoteShippedEmailTpl"
                  label="Email"
                  className="box-7"
                  disabled={
                    !(
                      tabQuoteInfoFieldsAccess?.status?.editable &&
                      hasEditAndDeleteAccess
                    )
                  }
                  isNewQuote={!quoteEditData?.id}
                />
              )} */}
            </div>        
          )}
        </div>
      )}
      <div className="row-fluid">
        {!!tabQuoteInfoFormsCompanyAccess && (
          <FormCard
            name="company-info"
            formFields={[companyInfoFormFields]}
            label="Company Info"
            className="box-4"
            additionalErrors={additionalErrors}
          />
        )}
        <div className="row-fluid box-8 md:gap-y-4">
          {!!tabQuoteInfoFormsBillingAddressFieldsAccess && (
            <FormCard
              name="client-billing-address"
              formFields={[billingAddressFormField]}
              label="Client Billing address"
              className="box-6"
            />
          )}
          {tabQuoteInfoFormsShippingAddressFieldsAccess && (
            <FormCard
              name="client-shipping-address"
              formFields={[shippingAddressFormField]}
              label="Client Shipping address"
              className={`box-6`}
              additionalButtons={[
                {
                  label: "Same As Billing",
                  color: "default",
                  name: "sameAsBilling",
                  onClick: () => {
                    setIsManualShippingAddressId(true);

                    updateShippingAddress({
                      address: clientBillingAddresses.address,
                      address2: clientBillingAddresses.address2,
                      attentionTo: clientBillingAddresses.attentionTo,
                      city: clientBillingAddresses.city,
                      state: clientBillingAddresses.state,
                      countryId: clientBillingAddresses.countryId,
                      zip: clientBillingAddresses.zip,
                    });
                  },
                  hidden:
                    !!quoteEditData.id || !quoteEditData.order.paymentType,
                },
              ]}
            />
          )}
          <div className="row-fluid mt-2 md:mt-0">
            {!!tabQuoteInfoFieldsAccess?.note && (
              <div className="box-6">
                <Input
                  value={note}
                  onChange={(value) => {
                    if (typeof value === "string") {
                      updateNote(value);
                    }
                  }}
                  name="note"
                  isTextarea={true}
                  rowCount={2}
                  className="edit-quote__column-content"
                  label="Note:"
                />
              </div>
            )}

            {!!quoteEditData?.id && (
              <div className="edit-quote__column-content box-6">
                <div className="btn-group">
                  {!!tabQuoteInfoButtonsAccess?.includes("save") &&
                    hasEditAndDeleteAccess && (
                      <button
                        className="btn btn-small btn-success"
                        onClick={onUpdateQuote}
                        disabled={
                          !isValidForms || isLoadingUpdateQuoteModuleServerData
                        }
                      >
                        <i
                          className={`${
                            isLoadingUpdateQuoteModuleServerData
                              ? "inline-block animate-spin icon-spin4"
                              : "icon-floppy"
                          } pr-1`}
                        />
                        Save
                      </button>
                    )}
                  {!!tabQuoteInfoButtonsAccess.includes("delete") &&
                    hasEditAndDeleteAccess &&
                    !!quoteEditData.id && (
                      <button
                        className="btn btn-small btn-warning"
                        onClick={() => {
                          if (quoteEditData.id) {
                            setDeleteId({
                              type: "quote",
                              id: quoteEditData.id,
                            });
                          }
                        }}
                      >
                        <i className={`icon-trash-empty pr-1`} />
                        Delete
                      </button>
                    )}
                </div>
                {!!tabQuoteInfoButtonsAccess.includes("downloadQuote") && (
                  <SelectCombobox
                    displayValue={
                      <span>
                        <i
                          className={`${
                            isLoadingDownloadQuote
                              ? "inline-block animate-spin icon-spin4"
                              : "icon-down-circled2"
                          } pr-1`}
                        />
                        Download quote
                      </span>
                    }
                    value={"unknown"}
                    onChange={(value) => {
                      const currentValue = value
                        .replace(" Template", "")
                        .toLowerCase();

                      onDownloadPDF("quote", currentValue);
                    }}
                    selectOptions={["UMD Template", "EP Template"]}
                    name="download-quote"
                    color="blue"
                    selectWidth="150px"
                    disabled={isLoadingDownloadQuote}
                  />
                )}
                {tabQuoteInfoButtonsAccess?.includes("downloadInvoice") && (
                  <SelectCombobox
                    displayValue={
                      <span>
                        <i
                          className={`${
                            isLoadingDownloadInvoice
                              ? "inline-block animate-spin icon-spin4"
                              : "icon-down-circled2"
                          } pr-1`}
                        />{" "}
                        Download invoice
                      </span>
                    }
                    value={"unknown"}
                    onChange={(value) => {
                      const currentValue = value
                        .replace(" Template", "")
                        .toLowerCase();

                      onDownloadPDF("invoice", currentValue);
                    }}
                    selectOptions={["UMD Template", "EP Template"]}
                    name="download-invoice"
                    color="blue"
                    selectWidth="150px"
                    disabled={isLoadingDownloadInvoice}
                  />
                )}
                {tabQuoteInfoButtonsAccess?.includes("downloadPackingSlip") && (
                  <SelectCombobox
                    displayValue={
                      <span>
                        <i
                          className={`${
                            isLoadingDownloadPacking
                              ? "inline-block animate-spin icon-spin4"
                              : "icon-down-circled2"
                          } pr-1`}
                        />{" "}
                        Download Packing Slip
                      </span>
                    }
                    value={"unknown"}
                    onChange={(value) => {
                      const currentValue = value
                        .replace(" Template", "")
                        .toLowerCase();

                      onDownloadPDF("packing", currentValue);
                    }}
                    selectOptions={["UMD Template", "EP Template"]}
                    name="download-packing"
                    color="blue"
                    selectWidth="150px"
                    disabled={isLoadingDownloadPacking}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {tabQuoteInfoFormsOrderProductsAccess && (
        <EditQuoteQuoteInfoTable
          stateTax={stateTax}
          accessEditProducts={!!tabQuoteInfoFormsOrderProductsAccess?.editable}
          accessDisableForSuppliers={!!tabQuoteInfoFormsOrderProductsAccess?.disableForSuppliers}
          accessShowSupplierAccess={!!tabQuoteInfoFormsOrderProductsAccess?.showSupplierAccess}
          products={quoteEditData?.orderProducts || []}
          isLoadingData={isLoadingData}
          setQuoteEditData={setQuoteEditData}
          quoteEditData={quoteEditData}
        />
      )}
      {roleName !== "supplier" && (
        <Fragment>
          <div>
            <CheckboxNew
              value={quoteEditData.useAdditionalLine}
              onChange={(value) => {
                setQuoteEditData(prevState => {
                  const tmpEditData = cloneDeep(prevState);
                  tmpEditData.useAdditionalLine = value;

                  return tmpEditData;
                })
              }}
              name="use-additional-line"
              label="Use Additional Line"
            />
          </div>
          {quoteEditData.useAdditionalLine &&
            !!quoteEditData.quoteAdditionalRows.length && (
              <TableNew
                heading={tableHeadingForQuickQuoteAdditionalLineTable}
                children={childrenForQuickQuoteAdditionalLineTable}
                countPages={
                  childrenForQuickQuoteAdditionalLineTable.length ? 1 : 0
                }
                setIsNeedUpdateTableData={() => {}}
              />
            )}
        </Fragment>
      )}
    </section>
  );
};

export default EditQuoteQuoteInfo;
