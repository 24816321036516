import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import FormCard from "../../../../components/Form/El/FormCard";
import { QuoteEditData } from "../../../../types/Quote";
import { FormFields, FormSelectOptionItem } from "../../../../types/Form";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_ORDER, CREATE_ORDER, CREATE_SHIPPING_TRACK, UPDATE_SHIPPING_TRACKS, DELETE_SHIPPING_TRACK } from "../../Services/Mutations/Mutations";
import { setNotification } from "../../../../helpers/cache";
import { editQuoteOrderSetObjForSending } from "./EditQuoteSetObjForSending";
import {cloneDeep} from "lodash";
import { ISplitShipping, ISplitShippingEditRow, ISplitShippingEditRows, ISplitShippingForm } from "components/model/ISplitShipping";
import { SHIPPING_TRACK_QUERY } from "modules/Quotes/Services/Queries/ShippingTrack";

const EditQuoteShippingInfo: FC<{
  quoteEditData: QuoteEditData;
  setQuoteEditData:  React.Dispatch<React.SetStateAction<QuoteEditData>>;
  creditCardList: FormSelectOptionItem[];
  setIsNeedUpdateQuote: () => void;
  updateClientCreditCard: (id: number) => void;
  tabShippingAndAdditionalInfoAccess: any;
  hasEditAndDeleteAccess: boolean;
  quote?: any;
}> = ({
  quoteEditData,
  setQuoteEditData,
  creditCardList,
  setIsNeedUpdateQuote,
  updateClientCreditCard,
  tabShippingAndAdditionalInfoAccess,
  hasEditAndDeleteAccess,
  quote,
}) => {
    const formAccess = useMemo(() => tabShippingAndAdditionalInfoAccess?.forms, [tabShippingAndAdditionalInfoAccess]);
    const splitShippingAccess = useMemo(() => !!tabShippingAndAdditionalInfoAccess?.splitShipping, [tabShippingAndAdditionalInfoAccess]);

    const formEditOrderAccess = useMemo(() => formAccess?.editOrder, [formAccess]);

    const formEditOrderButtonsAccess = useMemo(() => formEditOrderAccess?.buttons, [formEditOrderAccess]);
    const formEditOrderFieldsAccess = useMemo(() => formEditOrderAccess?.fields, [formEditOrderAccess]);

    const formEditOrderFieldsPaymentTypeAccess = useMemo(() => formEditOrderFieldsAccess?.paymentType, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsClientCreditCardAccess = useMemo(() => formEditOrderFieldsAccess?.clientCreditCard, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsShippingMethodAccess = useMemo(() => formEditOrderFieldsAccess?.shippingMethod, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsTrackingNumber1Access = useMemo(() => formEditOrderFieldsAccess?.trackingNumber1, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsTrackingNumber2Access = useMemo(() => formEditOrderFieldsAccess?.trackingNumber2, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsWatchTrackingNumber1Access = useMemo(() => formEditOrderFieldsAccess?.watchTracking1, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsWatchTrackingNumber2Access = useMemo(() => formEditOrderFieldsAccess?.watchTracking2, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsPaymentStatusAccess = useMemo(() => formEditOrderFieldsAccess?.paymentStatus, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsSpecialInstructionAccess = useMemo(() => formEditOrderFieldsAccess?.specialInstruction, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsMiscellaneousInfoAccess = useMemo(() => formEditOrderFieldsAccess?.miscellaneousInfo, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsNoteAccess = useMemo(() => formEditOrderFieldsAccess?.note, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsUuseUsFedexAcctAccess = useMemo(() => formEditOrderFieldsAccess?.useUpsFedexAcct, [formEditOrderFieldsAccess]);
    const formEditOrderFieldsCustomerPaysShippingAccess = useMemo(() => formEditOrderFieldsAccess?.customerPaysShipping, [formEditOrderFieldsAccess]);

    const order = useMemo(() => quoteEditData?.order, [quoteEditData]);

    const [updateOrderServerData] = useMutation(UPDATE_ORDER, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [createOrderServerData] = useMutation(CREATE_ORDER, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    const [isLoadingUpdateOrderServerData, setIsLoadingUpdateOrderServerData] =
      useState(false);

    const onUpdateOrderServerData = useCallback(async () => {
      setIsLoadingUpdateOrderServerData(true);

      if (order.id) {
        await updateOrderServerData({
          variables: {
            ...editQuoteOrderSetObjForSending(order),
            quoteId: quoteEditData.id,
          },
        })
          .then((res) => {
            const { data } = res;

            if (!data) return;

            const { updateOrder } = data;

            if (!updateOrder) return;

            setIsNeedUpdateQuote();
            setNotification([
              {
                type: "SUCCESS",
                message: "Shipping & Additional info successfully saved",
              },
            ]);
          })
          .finally(() => {
            setIsLoadingUpdateOrderServerData(false);
          });
        return
      }

      await createOrderServerData({
        variables: {
          ...editQuoteOrderSetObjForSending(order),
          quoteId: quoteEditData.id
        },
      })
        .then((res) => {
          const { data } = res;

          if (!data) return;

          const { createOrder } = data;

          if (!createOrder) return;

          setIsNeedUpdateQuote();
          setNotification([
            {
              type: "SUCCESS",
              message: "Shipping & Additional info successfully saved",
            },
          ]);
        })
        .finally(() => {
          setIsLoadingUpdateOrderServerData(false);
        });
    }, [order]);

    const updateOrder = useCallback(
      (data: Partial<QuoteEditData["order"]>) => {
        setQuoteEditData(prevState => {
          const tmpEditData = cloneDeep(prevState);
          tmpEditData.order = {
            ...tmpEditData.order,
            ...data
          }


          return tmpEditData;
        })
      },
      [setQuoteEditData],
    );
    const ShippingInfoFormField = useMemo(
      (): FormFields[] => [
        [
          {
            name: "paymentType",
            label: "PaymentType",
            onChange: (value) => {
              if (typeof value === "number") {
                updateOrder({
                  paymentType: value,
                });
              }
            },
            type: "select",
            subType: "paymentTypes",
            value: order?.paymentType,
            accessedFields: formEditOrderFieldsPaymentTypeAccess,
          },
          {
            name: "creditCardId",
            label: "Client Credit Card",
            onChange: (value) => {
              if (typeof value === "number") {
                updateClientCreditCard(value);
              }
            },
            type: "select",
            selectOptions: {
              optionsGroup: [{ options: creditCardList }],
            },
            value: order?.clientCreditCardId,
            accessedFields: formEditOrderFieldsClientCreditCardAccess,
          },
          {
            name: "shippingMethod",
            label: "Shipping Method",
            onChange: (value) => {
              if (typeof value === "number") {
                updateOrder({
                  shippingMethod: value,
                });
              }
            },
            type: "select",
            subType: "shippingMethods",
            value: order?.shippingMethod,
            accessedFields: formEditOrderFieldsShippingMethodAccess,
          },
          {
            name: "trackingNumber1",
            label: "Tracking Number 1",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  trackingNumber1: value,
                });
              }
            },
            type: "input",
            value: order?.trackingNumber1,
            accessedFields: formEditOrderFieldsTrackingNumber1Access,
          },
          {
            name: "watchTracking1",
            label: "Watch Tracking 1",
            onChange: (value) => {
              if (typeof value === "boolean") {
                updateOrder({
                  watchTracking1: value,
                });
              }
            },
            type: "checkbox",
            value: order?.watchTracking1,
            accessedFields: formEditOrderFieldsWatchTrackingNumber1Access,
          },
          {
            name: "trackingNumber2",
            label: "Tracking Number 2",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  trackingNumber2: value,
                });
              }
            },
            type: "input",
            value: order?.trackingNumber2,
            accessedFields: formEditOrderFieldsTrackingNumber2Access,
          },
          {
            name: "watchTracking2",
            label: "Watch Tracking 2",
            onChange: (value) => {
              if (typeof value === "boolean") {
                updateOrder({
                  watchTracking2: value,
                });
              }
            },
            type: "checkbox",
            value: order?.watchTracking2,
            accessedFields: formEditOrderFieldsWatchTrackingNumber2Access,
          },
          {
            name: "paymentStatus",
            label: "Payment Status",
            onChange: (value) => {
              if (typeof value === "number") {
                updateOrder({
                  paymentStatus: value,
                });
              }
            },
            type: "select",
            subType: "paymentStatuses",
            value: order?.paymentStatus,
            accessedFields: formEditOrderFieldsPaymentStatusAccess,
          },
        ],
        [
          {
            name: "specialInstructions",
            label: "Special Instructions",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  specialInstructions: value,
                });
              }
            },
            type: "input",
            inputOptions: {
              isTextarea: true,
              rowCount: 2,
            },
            value: order?.specialInstructions,
            accessedFields: formEditOrderFieldsSpecialInstructionAccess,
          },
          {
            name: "misc",
            label: "Miscellaneous Info",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  misc: value,
                });
              }
            },
            type: "input",
            inputOptions: {
              isTextarea: true,
              rowCount: 2,
            },
            value: order?.misc,
            accessedFields: formEditOrderFieldsMiscellaneousInfoAccess,
          },
          {
            name: "note",
            label: "Note",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  note: value,
                });
              }
            },
            type: "input",
            inputOptions: {
              isTextarea: true,
              rowCount: 2,
            },
            value: order?.note,
            accessedFields: formEditOrderFieldsNoteAccess,
          },
          {
            name: "useUpsFedexAcct",
            label: "Use Ups Fedex Acct",
            onChange: (value) => {
              if (typeof value === "string") {
                updateOrder({
                  useUpsFedexAcct: value,
                });
              }
            },
            type: "input",
            value: order?.useUpsFedexAcct,
            accessedFields: formEditOrderFieldsUuseUsFedexAcctAccess,
          },
          {
            name: "customerPaysShipping",
            label: "Customer Pays Shipping",
            onChange: (value) => {
              if (typeof value === "boolean") {
                updateOrder({
                  customerPaysShipping: value,
                });
              }
            },
            type: "checkbox",
            value: order?.customerPaysShipping,
            accessedFields: formEditOrderFieldsCustomerPaysShippingAccess,
          },
        ],
      ],
      [
        creditCardList,
        order,
        formEditOrderFieldsPaymentTypeAccess,
        formEditOrderFieldsClientCreditCardAccess,
        formEditOrderFieldsShippingMethodAccess,
        formEditOrderFieldsTrackingNumber1Access,
        formEditOrderFieldsTrackingNumber2Access,
        formEditOrderFieldsWatchTrackingNumber1Access,
        formEditOrderFieldsWatchTrackingNumber2Access,
        formEditOrderFieldsPaymentStatusAccess,
        formEditOrderFieldsSpecialInstructionAccess,
        formEditOrderFieldsMiscellaneousInfoAccess,
        formEditOrderFieldsNoteAccess,
        formEditOrderFieldsUuseUsFedexAcctAccess,
        formEditOrderFieldsCustomerPaysShippingAccess
      ],
    );


    // -- 
    // -- Split Shippings
    // --
    const [isShippingTrackEditing, setIsShippingTrackEditing] = useState<boolean>(false);

    const tableHeading = [
      "Track Number",
      "Description",
      "Created",
      "Author",
      "",
    ];

    // -- Server Query SHIPPING_TRACK_QUERY
    const { data: shippingTracks, loading: isShippingTracksLoading, refetch: refetchShippingTracks } = useQuery(
      SHIPPING_TRACK_QUERY,
      {
        skip: !quote?.id,
        variables: {
          quoteId: quote?.id,
        },
      },
    );
    
    const defaultShippingTracksEditRow : ISplitShippingEditRow = { 
      number: '',
      description: '',
    };
    const [shippingTracksEditRows, setShippingTracksEditRows] = useState<ISplitShippingEditRows>();

    const handleShippingTracksEditRows = <T extends keyof ISplitShippingEditRow>(id: string, key: T, value: ISplitShippingEditRow[T]) =>
      setShippingTracksEditRows((prevState) => ({
        ...prevState,
        [id]: {
          ...defaultShippingTracksEditRow,
          ...prevState?.[id],
          [key]: value,
        }
      }));

    // -- Server Mutation
    const [updateShippingTracks] = useMutation(UPDATE_SHIPPING_TRACKS, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });

    // -- Update form when traks list changes
    useEffect(() => {
      shippingTracks?.shippingTracks?.length 
      && setShippingTracksEditRows(shippingTracks?.shippingTracks.reduce((acc: ISplitShippingEditRows, shippingTrack: ISplitShipping) => ({
        ...acc,
        [shippingTrack.id]: {
          ...defaultShippingTracksEditRow,
          number: shippingTrack.number || '',
          description: shippingTrack.description || ''
        }
      }), {} as ISplitShippingEditRows));
    }, [shippingTracks?.shippingTracks]);

    const onEditShippingTracks = useCallback(async () => {
      setIsShippingTrackEditing(() => true);
      const updatedRows = await updateShippingTracks({
        variables: {
          shippingTracksEditRows: Object.entries(shippingTracksEditRows || {}).map(([id, data]) => ({
            id: Number(id),
            ...data,
          })),
        }
      });
      setIsShippingTrackEditing(() => false);

      if (updatedRows?.errors)
        return setNotification([{
          type: "ERROR",
          message: `Faild to update Split Shipping Tracks ${updatedRows?.errors}`,
        }]);  

      refetchShippingTracks();

      setNotification([ {
        type: "SUCCESS",
        message: "Shipping Tracks have been updated successfully",
      },]);       
    }, [shippingTracksEditRows]);

    // -- 
    // -- Add Split shipping
    // --
    const defaultNewSplitShippingForm = {
      number: '',
      quoteId: quote?.id || 0,
      description: '',
      watch: false,
    };

    const [isShippingTrackAdding, setIsShippingTrackAdding] = useState<boolean>(false);
    const [newSplitShipping, setNewSplitShipping] = useState<ISplitShippingForm>(defaultNewSplitShippingForm);

    const splitShippinghandler = <T extends keyof ISplitShippingForm>(key: T, value: ISplitShippingForm[T]) => 
      setNewSplitShipping((prevState) => ({
        ...prevState,
        [key]: value,
      }));

    useEffect(() => {
      quote.id && splitShippinghandler('quoteId', quote.id);
    }, [quote]);  

    // -- Fields
    const NewSplitShippingFormField = useMemo(
      (): FormFields[] => [
        [
          {
            name: "newSplitShippingNumber",
            label: "Tracking Number",
            onChange: (value) => splitShippinghandler('number', String(value) || ''),
            type: "input",
            value: newSplitShipping?.number,
            accessedFields: { editable: true },
          },
          // {
          //   name: "newSplitShippingWatch",
          //   label: "Watch",
          //   onChange: (value) => splitShippinghandler('watch', !!value),
          //   type: "checkbox",
          //   value: newSplitShipping?.watch,
          //   accessedFields: { editable: true },
          // },
          {
            name: "newSplitShippingDescription",
            label: "Description",
            onChange: (value) => splitShippinghandler('description', String(value) || ''),
            type: "input",
            value: newSplitShipping?.description,
            accessedFields: { editable: true },
            inputOptions: {
              isTextarea: true,
              rowCount: 2,
            },
          },
        ]
      ], [newSplitShipping]
    );

    // -- Create Shipping track : Server Mutation
    const [saveNewShippingTrack] = useMutation(CREATE_SHIPPING_TRACK, {
      onError: (error) => {
        setNotification([
          {
            type: "ERROR",
            message: `${error?.message}`,
          },
        ]);
      },
      notifyOnNetworkStatusChange: true,
    });  

    // -- Add handler
    const onAddNewShippingTrack = useCallback(async () => {
      setIsShippingTrackAdding(() => true);

      const result = await saveNewShippingTrack({
        variables: newSplitShipping,
      });

      setIsShippingTrackAdding(() => false);

      if (result?.errors)
        return setNotification([{
          type: "ERROR",
          message: `Faild to create Split Shipping Track ${result?.errors}`,
        }]); 

      setNotification([ {
        type: "SUCCESS",
        message: "New Shipping Track Have been saved successfully",
      },]);
      setNewSplitShipping(() => defaultNewSplitShippingForm);
      refetchShippingTracks();      
    }, [newSplitShipping]);

  // --
  // -- Delete shipping track
  // --

  // -- Delete Shipping track : Server Mutation
  const [deleteShippingTrack] = useMutation(DELETE_SHIPPING_TRACK, {
    onError: (error) => {
      setNotification([
        {
          type: "ERROR",
          message: `${error?.message}`,
        },
      ]);
    },
    notifyOnNetworkStatusChange: true,
  }); 

  const handleDeleteShippingTrack = (id: ISplitShipping['id']) => async () => {
    if (isShippingTracksLoading || isShippingTrackEditing) return;

    setIsShippingTrackEditing(() => true);

    const result = await deleteShippingTrack({
      variables: { id },
    });

    setIsShippingTrackEditing(() => false);

    if (result?.errors)
      return setNotification([{
        type: "ERROR",
        message: `Faild to delete Split Shipping Track ${result?.errors}`,
      }]); 

    setNotification([ {
      type: "SUCCESS",
      message: "New Shipping Track Have been deleted successfully",
    },]);
      
    refetchShippingTracks();
  };

    return (
      <section>
        <div className="row-fluid flex flex-col gap-4">
          <FormCard
            name="edit-order"
            formFields={ShippingInfoFormField}
            label="Edit Order"
            className="w-full"
            additionalButtons={[
              {
                label: "Save",
                icon: `${isLoadingUpdateOrderServerData
                    ? "inline-block animate-spin icon-spin4"
                    : "icon-floppy"
                  } pr-1`,
                color: "success",
                name: "sameAsBilling",
                onClick: () => {
                  onUpdateOrderServerData();
                },
                hidden: !quoteEditData.id || !formEditOrderButtonsAccess.includes('save') || !hasEditAndDeleteAccess,
                disabled: isLoadingUpdateOrderServerData,
              },
            ]}
          />

          {!!splitShippingAccess && !!newSplitShipping?.quoteId && (
            <FormCard
              name="edit-order"
              formFields={NewSplitShippingFormField}
              label="New Split Shipping Track Number"
              className="w-full"
              additionalButtons={[
                {
                  label: "Save And Send",
                  icon: `${isShippingTrackAdding
                      ? "inline-block animate-spin icon-spin4"
                      : "icon-floppy"
                    } pr-1`,
                  color: "success",
                  name: "addShippingTrack",
                  onClick: () => onAddNewShippingTrack(),
                  hidden: !quoteEditData.id || !formEditOrderButtonsAccess.includes('save') || !hasEditAndDeleteAccess,
                  disabled: isShippingTrackAdding || !(newSplitShipping.number || '').trim(),
                },
              ]}
            />
          )}

          {!!splitShippingAccess && !!shippingTracks?.shippingTracks?.length && (
            <div className="form-card w-full">
              <div className="form-card__header">
                <div className="label">Split Shipping Track Numbers</div>
                <div className="control">
                  <button
                    className="btn btn-small btn-success header-with-reactive-value-and-save-btn__btn"
                    onClick={() => onEditShippingTracks()}
                    disabled={isShippingTracksLoading || isShippingTrackEditing}
                  >
                    {isShippingTracksLoading || isShippingTrackEditing
                      ? <i className="inline-block animate-spin icon-spin4" />
                      : <i className="icon-floppy pr-1" />
                    }
                    Save
                  </button>
                </div>
              </div>

              <div className="form-card__content-list">
                <table className="w-full border-collapse">
                  <thead className="bg-[#E4E6E9] ">
                    <tr className="[background:linear-gradient(to_bottom,#F8F8F8_0%,#ECECEC_100%)]">
                      {tableHeading?.map((heading, index) => (
                        <th
                          key={`tplHead-${index}`}
                          className={`text-[13px] leading-5 text-[#707070] border p-2 border-[#DDD] whitespace-nowrap ${
                            index === 0 && "w-[20px]"
                          }`}
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {shippingTracks?.shippingTracks?.map((shippingTrack: ISplitShipping, indx: number) => (
                      <tr key={`tplBody-${shippingTrack.id}`} className="text-sm hover:bg-[#f1f1f1]">
                        <td className="p-2 min-w-[200px] max-w-[250px] w-[20%] border-[1px] border-[#ccc] ">
                          <input
                            name={`shipping-track-number-${shippingTrack.id}`}
                            value={shippingTracksEditRows?.[shippingTrack.id]?.number || ''}
                            // disabled={loading}
                            onChange={(e) => handleShippingTracksEditRows(String(shippingTrack.id), 'number', e?.target?.value)}                        
                            className="w-full border border-[#D5D5D5] h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica pr-6"
                          />
                        </td>
                        <td className="p-2 min-w-[200px] w-[40%] border-[1px] border-[#ccc]">
                          <input
                            name={`shipping-track-description-${shippingTrack.id}`}
                            value={shippingTracksEditRows?.[shippingTrack.id]?.description || ''}
                            // disabled={loading}
                            onChange={(e) => handleShippingTracksEditRows(String(shippingTrack.id), 'description', e?.target?.value)}                        
                            className="w-full border border-[#D5D5D5] h-[30px] focus:border-[#F59942] focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] peer pl-[6px] placeholder:text-sm placeholder:text-[#aaaaaa] text-sm flex items-center text-[#838281] placeholder:font-heltivica pr-6"
                          />
                        </td>
                        <td className="p-2 text-center border-[1px] border-[#ccc]">
                          {shippingTrack?.createdAt || ''}
                        </td>
                        <td className="p-2 text-center border-[1px] border-[#ccc]">
                          {shippingTrack?.user?.email || shippingTrack?.user?.firstName || '-'}
                        </td>

                        <td className="p-2 text-center border-[1px] border-[#ccc]">
                          <span
                            role="button"                            
                            tabIndex={-1}
                            onClick={handleDeleteShippingTrack(shippingTrack.id)}
                            className={`
                              flex gap-1 justify-center text-red-600 hover:underline
                              ${isShippingTracksLoading || isShippingTrackEditing ? 'opacity-50' : ''}
                            `}
                          >
                            <i className="icon-cancel text-[18px]" />
                          </span>
                        </td>                  
                      </tr>
                    ))}
                </tbody>
                </table>
              </div>
            </div>
          )}
          
        </div>
      </section>
    );
  };

export default EditQuoteShippingInfo;
