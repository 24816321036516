import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { setNotification, userContextData } from "helpers/cache";
import { CRMSETTINGS_QUOTE_SHIPPED_EMAIL_TPLS } from "modules/AdminDashboard/Services/Queries/Queries";
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import CRMSetting from "types/CRMsetting";
import {
  CLIENT_ACCESS_LEVEL_QUERY,
  COUNTRIES_QUERY, EXPENSES_QUERY,
  GENDERS_QUERY,
  INDUSTRY_TAGS_QUERY,
  LEAD_SOURCE_QUERY, PAYMENT_STATUSSES_QUERY,
  PAYMENT_TYPES_QUERY, QUOTE_IMAGE_TYPES_QUERY,
  QUOTE_LEAD_STATUSES_QUERY,
  QUOTE_STATUSES_QUERY,
  SHIPPIND_ADDRESS_TYPES_QUERY,
  SHIPPING_METHODS_QUERY,
  SUPPLIERS_QUERY,
  USERS_QUERY
} from "../../../modules/Services/Queries/Common/CommonQueries";
import { AddressType } from "../../../types/AddressType";
import { ClientAccessLevel } from "../../../types/ClientAccessLevel";
import { Country } from "../../../types/Country";
import { Expense } from "../../../types/Expense";
import { FormSelectGroupOption, FormSelectValue } from "../../../types/Form";
import { Gender } from "../../../types/Gender";
import { ImageType } from "../../../types/ImageType";
import { IndustryTag } from "../../../types/IndustryTag";
import { LeadSource } from "../../../types/LeadSource";
import { LeadStatus } from "../../../types/LeadStatus";
import { PaymentStatus } from "../../../types/PaymentStatus";
import { PaymentType } from "../../../types/PaymentType";
import { QuoteStatus } from "../../../types/QuoteStatus";
import { ShippingMethod } from "../../../types/ShippingMethod";
import { User } from "../../../types/User";
import Select from "./Select";

interface SelectOptionsFromServer {
  users: User[];
  countries: Country[];
  genders: Gender[];
  clientAccessLevels: ClientAccessLevel[];
  industryTags: IndustryTag[];
  shippingAddressTypes: AddressType[];
  leadSource: LeadSource[];
  configQuotesLeadStatuses: LeadStatus[];
  configQuotesStatuses: QuoteStatus[];
  shippingMethods: ShippingMethod[];
  paymentTypes: PaymentType[];
  suppliers: User[];
  paymentStatuses: PaymentStatus[];
  expenses: Expense[];
  quoteImageTypes: ImageType[];
  quoteShippedEmailTpl: CRMSetting[];
}

const SelectFromServerData: FC<{
  isNewQuote?: boolean;
  value: FormSelectValue;
  onChange: (value: FormSelectValue) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  classNameForSelect?: string;
  isIncorrectValue?: boolean;
  disabled?: boolean;
  labelWidth?: string;
  label?: string | ReactNode;
  type:
    | "users"
    | "countries"
    | "genders"
    | "clientAccessLevels"
    | "industryTags"
    | "shippingAddressTypes"
    | "leadSource"
    | "configQuotesLeadStatuses"
    | "configQuotesStatuses"
    | "shippingMethods"
    | "paymentTypes"
    | "suppliers"
    | "paymentStatuses"
    | "expenses"
    | "quoteImageTypes"
    | "quoteShippedEmailTpl";
  selectWidth?: string;
}> = ({
  isNewQuote,
  value,
  onChange,
  onFocus,
  onBlur,
  className,
  classNameForSelect,
  isIncorrectValue,
  disabled,
  labelWidth,
  label,
  type,
  selectWidth,
}) => { 
  const queryList = useMemo(() => ({
    users: USERS_QUERY,
    countries: COUNTRIES_QUERY,
    genders: GENDERS_QUERY,
    clientAccessLevels: CLIENT_ACCESS_LEVEL_QUERY,
    industryTags: INDUSTRY_TAGS_QUERY,
    shippingAddressTypes: SHIPPIND_ADDRESS_TYPES_QUERY,
    leadSource: LEAD_SOURCE_QUERY,
    configQuotesLeadStatuses: QUOTE_LEAD_STATUSES_QUERY,
    configQuotesStatuses: QUOTE_STATUSES_QUERY,
    shippingMethods: SHIPPING_METHODS_QUERY,
    paymentTypes: PAYMENT_TYPES_QUERY,
    suppliers: SUPPLIERS_QUERY,
    paymentStatuses: PAYMENT_STATUSSES_QUERY,
    expenses: EXPENSES_QUERY,
    quoteImageTypes: QUOTE_IMAGE_TYPES_QUERY,
    quoteShippedEmailTpl: CRMSETTINGS_QUOTE_SHIPPED_EMAIL_TPLS,
  }), [])

  const [getDataByType] = useLazyQuery(queryList[type], {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setSelectOptions([])
      setNotification([
        {
          type: "ERROR",
          message: `${error?.message}`,
        },
      ]);
    },
  });

  const [isLoadingSelectData, setIsLoadingSelectData] = useState(true)

  const getDataByTypeHandle = useCallback(async () => {
    /*if (disabled) {
      setIsLoadingSelectData(false);
      return;
    }*/
    setIsLoadingSelectData(true);
    await getDataByType().then((res) => {     
      if (!res || !res.data) {
        setSelectOptions([])
        return;
      }

      setSelectOptionValueByType(res.data);
      setDefaultSelectValueByType();
    }).finally(() => {
      setIsLoadingSelectData(false);
    })
  }, [])

  const userDetails: any = useReactiveVar(userContextData);

  const userId = useMemo(() => userDetails?.user?.id, [userDetails]);
  const roleName = useMemo(
    () => userDetails?.user?.roles[0]?.name?.toLowerCase(),
    [userDetails],
  );

  const [selectOptions, setSelectOptions] = useState<FormSelectGroupOption[]>(
    [],
  );

  const [defaultSelectValue, setDefaultSelectValue] =
    useState<FormSelectValue>(undefined);

  const setSelectOptionValueByType = useCallback((options: SelectOptionsFromServer) => {
    if (!(type && options[type])) return;

    if (type === "users") {
      setSelectOptions([
        {
          label: "activeUsers",
          options: options[type]
              .filter((user) => user && !user.isDeleted)
              .map((user) => ({
                id: user.id,
                value: user.userName,
              })),
        },
        {
          label: "deletedUsers",
          options: options[type]
              .filter((user) => user && user.isDeleted)
              .map((user) => ({
                id: user.id,
                value: user.userName,
              })),
        },
      ]);

      return;
    }

    if (type === "countries") {
      setSelectOptions([
        {
          options: options[type].map((country) => ({
            id: country.id,
            value: country.name,
          })),
        },
      ]);

      return;
    }

    if (type === "genders") {
      setSelectOptions([
        {
          options: options[type].map((gender) => ({
            id: parseInt(gender.label),
            value: gender.value,
          })),
        },
      ]);

      return;
    }

    if (type === "quoteShippedEmailTpl") {      
      setSelectOptions([
        {
          options: options[type].map(({ id, value}) => ({
            id,
            value,
          })),
        },
      ]);

      return;
    }

    if (type === "clientAccessLevels") {
      setSelectOptions([
        {
          options: options[type].map((clientAccessLevel) => ({
            id: parseInt(clientAccessLevel.label),
            value: clientAccessLevel.value,
          })),
        },
      ]);

      return;
    }

    if (type === "industryTags") {
      setSelectOptions([
        {
          options: options[type].map((industryTag) => ({
            id: industryTag.id,
            value: industryTag.name,
          })),
        },
      ]);

      return;
    }

    if (type === "shippingAddressTypes") {
      setSelectOptions([
        {
          options: options[type].map(
              (shippingAddressType) => shippingAddressType.value,
          ),
        },
      ]);

      return;
    }

    if (type === "leadSource") {
      setSelectOptions([
        {
          options: options[type].map((leadSource) => ({
            id: parseInt(leadSource.label),
            value: leadSource.value,
          })),
        },
      ]);

      return;
    }

    if (type === "configQuotesLeadStatuses") {
      setSelectOptions([
        {
          options: options[type].map((leadStatus) => ({
            id: leadStatus.id,
            value: leadStatus.name,
          })),
        },
      ]);

      return;
    }

    if (type === "configQuotesStatuses") {
      setSelectOptions([
        {
          options: options[type]
            .filter((option) => {
              if (value !== option.id && ((option.position > 3 && roleName === 'sales') || (roleName === 'supplier' && option.id !== 6)) && (isNewQuote || !disabled)) {
                return false;
              }
              return true
            })
            .map((paymentStatus) => {
              return {
                id: paymentStatus.id,
                value: paymentStatus.name,
              }
            }),
        },
      ]);

      return;
    }

    if (type === "suppliers") {
      setSelectOptions([
        {
          options: [
            { id: 0, value: "" },
            ...options[type].map((supplier) => ({
              id: supplier.id,
              value: supplier.userName,
            })),
          ],
        },
      ]);
    }

    if (type === "paymentTypes") {
      setSelectOptions([
        {
          options: options[type].map((paymentType) => ({
            id: +paymentType.label,
            value: paymentType.value,
          })),
        },
      ]);
    }

    if (type === "shippingMethods") {
      setSelectOptions([
        {
          options: options[type].map((shippingMethod) => ({
            id: +shippingMethod.label,
            value: shippingMethod.value,
          })),
        },
      ]);
    }

    if (type === "paymentStatuses") {
      setSelectOptions([
        {
          options: options[type].map((paymentStatus) => ({
            id: +paymentStatus.label,
            value: paymentStatus.value,
          })),
        },
      ]);
    }

    if (type === "expenses") {
      setSelectOptions([
        {
          options: options[type].map((expense) => ({
            id: expense.id,
            value: expense.name,
          })),
        },
      ]);

      return;
    }

    if (type === "quoteImageTypes") {
      setSelectOptions([
        {
          options: options[type].map((imageType) => ({
            id: parseInt(imageType.label),
            value: imageType.value,
          })),
        },
      ]);

      return;
    }
  }, [type])

  const setDefaultSelectValueByType = useCallback(() => {
    if (type === "users") {
      setDefaultSelectValue(userId || 0);
      return;
    }

    if (type === "countries" || type === "expenses") {
      setDefaultSelectValue(1);
      return;
    }

    if (type === "shippingAddressTypes") {
      setDefaultSelectValue("Shipping");
      return;
    }

    setDefaultSelectValue(0);
  }, [userId, type])

  const withNullbaleValue = useMemo(() => {
    return type === 'paymentTypes' || type === 'shippingMethods' || type === 'paymentStatuses'
  }, [type])

  useEffect(() => {
    if (type) {
      getDataByTypeHandle()
    }
  }, [type]);
  return (
    <Select
      value={value}
      defaultValue={defaultSelectValue}
      optionsGroups={selectOptions}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      classNameForSelect={classNameForSelect}
      isIncorrectValue={isIncorrectValue}
      disabled={disabled}
      name={`${type}-select`}
      loading={isLoadingSelectData}
      labelWidth={labelWidth}
      label={label}
      selectWidth={selectWidth}
      withNullbaleValue={withNullbaleValue}
    />
  );
};

export default SelectFromServerData;
