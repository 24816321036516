import { gql } from "@apollo/client";

export const EDIT_CLIENT_QUERIES = gql`
  query editClientQueries($clientId: Int!) {
    genders {
      label
      value
    }
    clientAccessLevels {
      label
      value
    }
    industryTags {
      id
      name
    }
    countries {
      id
      name
    }
    shippingAddressTypes {
      label
      value
    }
    users {
      id
      userName
      firstName
      lastName
      isDeleted
    }
    states {
      id
      name
      tax
    }
    client(id: $clientId) {
      id
      user {
        id
        userName
        firstName
        lastName
        email
      }
      refs {
        id
        createdAt
        total
        quoteAmount
        accountName
      }
      ref {
        id
        email
        firstName
        lastName
        company
        accountName
      }
      firstName
      lastName
      company
      email
      phone
      fax
      sex
      anumber
      phone1
      jobTitle
      accountName
      webAdress
      active
      lastBillingAddress {
        id
        address
        city
        country {
          id
          name
        }
        zip
        attentionTo
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        address2
        typeAddress
        isDelete
      }
      defaultContact {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        defaultShipping
        defaultBilling
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        sex
        isDelete

        birthday
      }
      accessLevelCd
      temporaryAccessDate
      hasOrdered
      clientContacts {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
            createdAt
            updatedAt
          }
          zip
          attentionTo
          client {
            id
            user {
              id
              userName
              firstName
              lastName
              email
            }
            firstName
            lastName
            company
            email
          }
        }
        defaultShipping
        defaultBilling
        client {
          id
          user {
            id
            userName
            firstName
            lastName
            email
          }
          firstName
          lastName
          company
          email
        }
        sex
        isDelete
        birthday
      }
      clientCreditCards {
        id
        holderName
        expirationDate
        cardNumber
        createdAt
        verificationNumber
        creditCardType
        isDelete
        client {
          id
          firstName
          lastName
          company
          email
        }
      }
      shippingAddresses {
        id
        address
        city
        state
        country {
          id
          name
        }
        zip
        attentionTo
        address2
        typeAddress
        isDelete
        client {
          id
          user {
            id
          }
          firstName
          lastName
          company
          email
        }
      }
      industryTag {
        id
        name
      }
    }
  }
`;
